import validate from './validation';

/**
 *
 * Validate field or state
 * Updates component state if has errors
 * Required by child component
 * @param {*} self - context
 * @param {*} data - validated data
 * @returns {bool} of result
 *
 */
export default function (self, data = self.state, closure = false, with_addon = true) {
  let errors = validate(data, self, closure, with_addon);

  if (errors) {
    for (let key in errors) {
      self.setState((state) => ((state[key] = { ...state[key], ...errors[key] }), state));
    }
  }

  return !errors;
}
