import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Menu, Icon, Checkbox, Row, Col } from 'antd';
import defaults from 'DEFAULTS';
import { tConsume } from 'TRANSLATION';
import UITooltip from '@atoms/ui-tooltip';

class FieldsControl extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };
  }

  makeFieldsList(fields) {
    return fields.map(({ title, value, dataIndex }, index) => {
      if (!dataIndex || !title) {
        return null;
      }

      return (
        <Menu.Item key={dataIndex}>
          <Row
            gutter={10}
            type="flex"
            justify="space-between"
            onClick={() => this.handleFields(index)}
          >
            <Col>{title}</Col>
            <Col>
              <Checkbox checked={value} />
            </Col>
          </Row>
        </Menu.Item>
      );
    });
  }

  handleFields(index) {
    this.props.handleFields(index);
  }

  handleClick = () => {
    this.setState({ visible: !this.state.visible });
  };

  handleVisibleChange = (flag) => {
    this.setState({ visible: flag });
  };

  render() {
    const { t, fields } = this.props;
    const { visible } = this.state;

    return (
      <Dropdown
        trigger={['click']}
        placement="bottomRight"
        overlayStyle={{ paddingTop: 15 }}
        overlay={<Menu selectable>{this.makeFieldsList(fields)}</Menu>}
        onVisibleChange={(flag) => this.handleVisibleChange(flag)}
        visible={visible}
      >
        <UITooltip title={this.props.t('tooltip.table_fields')} hide={visible}>
          <Button
            htmlType="submit"
            type="primary"
            size={defaults.antd.button.size}
            onClick={() => this.handleClick()}
            block
          >
            {t('button.fields')} <Icon type="down" />
          </Button>
        </UITooltip>
      </Dropdown>
    );
  }
}

export default tConsume(FieldsControl);

FieldsControl.propTypes = {
  fields: PropTypes.array.isRequired,
  handleFields: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
