// config {object} = {
//      start: (optional) animation init value in ms
//      offset: (optional) generally offset value of all steps
//      i: animated components count
//      options : (optional) object with individual parameters
//      options = {
//          [index] : {condition, offset}
//          //Condition may be index number of element in offset array with same conditions for render
//      }
// }
//
// return array of index strings
export default ({ start, offset, i, options }) => {
  const _defaults = {
    start: 300,
    offset: 200,
  };
  const result = [];

  if (!i || isNaN(+i)) {
    return result;
  }

  let step = start || start == 0 ? start : _defaults.start;

  if (!offset && offset != 0) {
    offset = _defaults.offset;
  }

  for (let index = 0; index < i; index++) {
    result.push(makeOffsets(index));
  }

  return result;

  function makeOffsets(index) {
    if (options && options[index]) {
      const opt = options[index];

      if ('condition' in opt) {
        let condition = opt.condition;

        //Check condition of other element if condition is a number
        if (
          !isNaN(+condition) &&
          typeof +condition === 'number' &&
          options[condition] &&
          'condition' in options[condition]
        ) {
          condition = options[condition].condition;
        }
        //

        if (!condition) {
          return null;
        }
      }

      if (index != 0 && 'offset' in opt) {
        step = step + opt.offset;
        return step.toString();
      }
    }

    step = step + offset;
    return step.toString();
  }
};
