import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Typography, Icon, Row, Col, Button } from 'antd';

import { sessionUpdate, sessionClose, sessionModalClose } from '@features/sessionSlice';
import { Strings } from 'HELPERS';
import defaults from 'DEFAULTS';
import { tConsume } from 'TRANSLATION';

const SessionTimeout = ({ userType, t }) => {
  const { loading, visible, logout } = useSelector((state) => state.session);
  const dispatch = useDispatch();

  useEffect(() => {
    Modal.destroyAll();
  }, []);

  const handleCloseSessionModal = () => dispatch(sessionModalClose());

  const handleCloseSession = () => dispatch(sessionClose());

  const handleUpdateSession = () => dispatch(sessionUpdate());

  if (logout) {
    return <Redirect to={`/${userType}/logout`} />;
  }

  return (
    <Modal
      visible={visible}
      title={null}
      footer={null}
      zIndex={1100}
      onCancel={handleCloseSessionModal}
    >
      <div className="align-center" style={{ margin: '50px 0' }}>
        <div>
          <Icon type="logout" className="main-color-1" style={{ fontSize: 100 }} />
        </div>

        <h1 style={{ textAlign: 'center' }}>{t('notification.sessionTimeout.message')}</h1>

        <div style={{ margin: 'auto', maxWidth: 350 }}>
          <Typography.Text type="secondary">
            {`${Strings.Compile(t('notification.sessionTimeout.description'), [
              defaults.website.title,
              5,
            ])} `}
          </Typography.Text>
        </div>

        {t('notification.sessionTimeout.question')}
      </div>

      <Row gutter={12}>
        <Col md={12}>
          <Button size={defaults.antd.button.size} onClick={handleCloseSession} block>
            {t('button.no')}
          </Button>
        </Col>
        <Col md={12}>
          <Button
            type="primary"
            size={defaults.antd.button.size}
            loading={loading}
            onClick={handleUpdateSession}
            block
          >
            {t('button.yes')}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default tConsume(SessionTimeout);

SessionTimeout.propTypes = {
  userType: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};
