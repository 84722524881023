import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { clearUserToken } from '@features/userTokenSlice';

import { Modal } from 'antd';

import defaults from 'DEFAULTS';
import { tConsume } from 'TRANSLATION';

import { BeneficiarySuccess } from 'IMAGE';

class Logout extends Component {
  componentDidMount() {
    const { t, userType, clearUserToken, userToken } = this.props;

    if (userType === userToken.tokenType) {
      clearUserToken();
      Modal.destroyAll();

      Modal.info({
        title: null,
        icon: false,
        autoFocusButton: true,
        okButtonProps: {
          size: defaults.antd.button.size,
          block: true,
        },
        content: (
          <div className="align-center" style={{ margin: '50px 0' }}>
            <div>
              <BeneficiarySuccess height={100} style={{ margin: '0 auto 50px' }} />
            </div>
            <h2 className="align-center">{t('component.logout.header')}</h2>
            <p className="align-center">{t('component.logout.subHeader')}</p>
          </div>
        ),
      });
    }
  }

  render() {
    const { userType } = this.props;

    return <Redirect to={`/${userType}`} />;
  }
}

const mapStateToProps = (state) => {
  return {
    userToken: state.userToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearUserToken: (path) => dispatch(clearUserToken(path)),
  };
};

export default tConsume(connect(mapStateToProps, mapDispatchToProps)(Logout));

Logout.propTypes = {
  userType: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  clearUserToken: PropTypes.func.isRequired,
  userToken: PropTypes.object,
  redirectPath: PropTypes.string,
  t: PropTypes.func.isRequired,
};
