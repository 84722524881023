import Rollbar from 'rollbar';
import defaults from 'DEFAULTS';

export const rollbar = new Rollbar({
  accessToken: defaults.variables.rollbar_access_token,
  environment: process.env.NODE_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: { environment: window.location.host },
});
