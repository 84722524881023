import api from 'API';
import axios from 'axios';
import getRoute from './getRoute';
import { store } from 'STORE';

export default (route, location, callback) => {
  const userType = store.getState().userToken.tokenType;
  axios(api.users.profile.progress(userType).AXIOS('get')).then((response) => {
    callback(getRoute(response, route, location, userType));
  });
};
