import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import isObject from 'lodash.isobject';

import api from 'API';
import { clearUserToken } from './userTokenSlice';
import { withNotificationForError } from './helper';

export const fetchCaseData = createAsyncThunk(
  'cases/fetchCaseData',
  withNotificationForError(async ({ id }) => {
    if (id === 'new') return null;

    const response = await axios(api.case_management.cases(id).AXIOS('get'));
    const data = response.data.data;
    data.description = isObject(data.description)
      ? JSON.stringify(data.description, undefined, 4)
      : data.description;

    return data;
  })
);

export const fetchUnreadMessages = createAsyncThunk(
  'cases/fetchUnreadMessages',
  withNotificationForError(async (payload, { getState }) => {
    const userType = getState().userToken.tokenType;
    let requestUrl = api.communication.client.unreadMessages();

    if (userType === 'aemi') {
      requestUrl = api.communication.aemi.unreadMessages();
    }

    const response = await axios(requestUrl.AXIOS('get'));

    return {
      count: response.data.count,
      data: response.data.data,
    };
  }),
  {
    condition: (payload, { getState }) => {
      const userType = getState().userToken.tokenType;
      if (!userType) return false;
    },
  }
);

const initialState = {
  loading: true,
  currentCase: null,
  comments: [],
  unread: {
    count: 0,
    data: [],
  },
};

const casesSlice = createSlice({
  name: 'cases',
  initialState: initialState,
  reducers: {
    clearCurrentCase(state) {
      state.currentCase = null;
      state.comments = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCaseData.pending, (state) => {
        state.loading = true;
        state.currentCase = null;
      })
      .addCase(fetchCaseData.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        state.currentCase = action.payload;
      })
      .addCase(fetchCaseData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(fetchUnreadMessages.fulfilled, (state, action) => {
        state.unread = {
          count: action.payload.count,
          data: action.payload.data,
        };
      })
      .addCase(fetchUnreadMessages.rejected, (state) => {
        state.unread = initialState.unread;
      })
      .addCase(clearUserToken, () => {
        return initialState;
      });
  },
});

export const { clearCurrentCase } = casesSlice.actions;
export default casesSlice.reducer;
