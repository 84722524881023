import isObject from 'lodash.isobject';

const getDefaultKeys = (menuConfig = { top: [], bottom: [] }, { pathname }) => {
  if (
    !isObject(menuConfig) ||
    !menuConfig.hasOwnProperty('top') ||
    !menuConfig.hasOwnProperty('bottom')
  ) {
    return {
      defaultOpenKeys: [],
      defaultSelectedKeys: [],
    };
  }

  const items = [...menuConfig.top, ...menuConfig.bottom];
  let select = [];
  let open = [];

  const matchPath = (it) => it.path && pathname.includes(it.path);

  const checkActive = (items) => {
    items.forEach((item) => {
      if ('subItems' in item) {
        const sub = item.subItems.filter((it2) => matchPath(it2));
        if (sub.length > 0) {
          open.push(item);
        }

        checkActive(item.subItems);
      } else {
        if (matchPath(item)) {
          select.push(item);
        }
      }
    });
  };

  checkActive(items);

  // const arr = select.sort((a, b) => {
  //   const aIndex = pathname.lastIndexOf(a.path);
  //   const bIndex = pathname.lastIndexOf(b.path);
  //   console.log('aIndex', aIndex, 'bIndex', bIndex);
  //   return aIndex - bIndex;
  // });
  // console.log(arr);

  return {
    defaultOpenKeys: open.map((item) => `${item.name} - ${item.path ? item.path : ''}`),
    defaultSelectedKeys: select.map((item) => `${item.name} - ${item.path ? item.path : ''}`),
  };
};

export default getDefaultKeys;
