import React from 'react';

export default function Template() {
  return (
    <div>
      <h3>
        ”Ultimate Beneficial Owner” means natural person who ultimately owns or controls the entity
        or the legal arrangement.
      </h3>
      <p>In a company, an “Beneficial Owner” means any person who:</p>
      <ul>
        <li>
          owns or controls, directly or indirectly, including through a trust or bearer share
          holding, more than 25% of the issued share capital of the company;
        </li>
        <li>
          is directly or indirectly, entitled to exercise or control the exercise of more than 25%
          of voting rights at general meetings of the company;
        </li>
        <li>exercises ultimate control over the company.</li>
      </ul>
      <br />
      <p>In a partnership, an “Beneficial Owner” means any person who:</p>
      <ul>
        <li>
          is entitled, directly or indirectly, to exercise or control the exercise of more than 25%
          of the voting rights in the partnership;
        </li>
        <li>
          is entitled to or controls, directly or indirectly, 25% of the capital or profits of the
          partnership;
        </li>
        <li>exercises ultimate control over the management of the partnership.</li>
      </ul>
      <br />
      <p>In a trust and foundation, an “Beneficial Owner” means any person who is:</p>
      <ul>
        <li>
          {' '}
          an individual who is entitled to a vested interest in the capital of the trust property
          (whether the interest is in possession or in remainder or reversion and whether it is
          defeasible or not);
        </li>
        <li>the settlor of the trust;</li>
        <li>a protector or enforcer of the trust;</li>
        <li>an individual who has ultimate control over the trust.</li>
      </ul>
    </div>
  );
}
