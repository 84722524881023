import StringMask from 'string-mask';

/* use these masks for show information to user */
const accountNumberForDisplay = new StringMask(
  'AAAA AAAA AAAA AAAA AAAA AAAA AAAA AAAA AAAA AAAAA AAAA AA'
);

const accountIbanForDisplay = new StringMask('AAAA AAAA AAAA AAAA AAAA AAAA AAAA AAAA AA');

const sortCodeForDisplay = new StringMask('AA-AA-AA');

/* use these methods for formatting input value */
const accountNumberMaskForInput = ({ newValue, prevValue, startPos, endPos, pasted }) => {
  let pastedValueEmptySpacesCount = 0;

  if (pasted) {
    pastedValueEmptySpacesCount = accountNumberForDisplay.apply(newValue).split(' ').length - 1;
  }

  let tempStartPos = startPos + pastedValueEmptySpacesCount;
  let tempEndPos = endPos + pastedValueEmptySpacesCount;
  const trimValue = accountNumberForRequest(newValue);
  const spacedStr = accountNumberForDisplay.apply(trimValue);
  const spacedStrLength = spacedStr.length;

  // input a new letter
  if (newValue.length > prevValue.length) {
    if (
      newValue.length < spacedStrLength &&
      (spacedStr[tempStartPos] === ' ' || spacedStr[tempStartPos - 1] === ' ')
    ) {
      tempStartPos++;
      tempEndPos++;
    }

    if (spacedStrLength === prevValue.length) {
      tempStartPos--;
      tempEndPos--;
    }
  }

  // delete a letter
  if (newValue.length < prevValue.length) {
    if (newValue.length > trimValue.length && newValue[newValue.length - 1] === ' ') {
      tempStartPos--;
      tempEndPos--;
    }
  }

  return {
    value: spacedStr[spacedStrLength - 1] === ' ' ? spacedStr.trim() : spacedStr,
    startPos: tempStartPos,
    endPos: tempEndPos,
  };
};

/* use these methods for prepare data before sent to server */
const accountNumberForRequest = (value) => {
  const valueWithoutSpace = value.replace(/[\W_]+/g, '');
  return valueWithoutSpace?.toUpperCase();
};

const accountIbanMaskForInput = ({ newValue, prevValue, startPos, endPos, pasted }) => {
  let pastedValueEmptySpacesCount = 0;

  if (pasted) {
    pastedValueEmptySpacesCount = accountIbanForDisplay.apply(newValue).split(' ').length - 1;
  }

  let tempStartPos = startPos + pastedValueEmptySpacesCount;
  let tempEndPos = endPos + pastedValueEmptySpacesCount;
  const trimValue = accountNumberForRequest(newValue);
  const spacedStr = accountIbanForDisplay.apply(trimValue);
  const spacedStrLength = spacedStr.length;

  // input a new letter
  if (newValue.length > prevValue.length) {
    if (
      newValue.length < spacedStrLength &&
      (spacedStr[tempStartPos] === ' ' || spacedStr[tempStartPos - 1] === ' ')
    ) {
      tempStartPos++;
      tempEndPos++;
    }

    if (spacedStrLength === prevValue.length) {
      tempStartPos--;
      tempEndPos--;
    }
  }

  // delete a letter
  if (newValue.length < prevValue.length) {
    if (newValue.length > trimValue.length && newValue[newValue.length - 1] === ' ') {
      tempStartPos--;
      tempEndPos--;
    }
  }

  return {
    value: spacedStr[spacedStrLength - 1] === ' ' ? spacedStr.trim() : spacedStr,
    startPos: tempStartPos,
    endPos: tempEndPos,
  };
};

export {
  accountNumberForDisplay,
  sortCodeForDisplay,
  accountIbanMaskForInput,
  accountNumberMaskForInput,
  accountNumberForRequest,
};
