import React from 'react';
import FormDate from '@atoms/form/form-item/FormDate';
import setDisabled from '@atoms/form/funcs/setDisabled';

export default (self = {}, array = []) => {
  const state = self.state;

  if (!state) {
    return null;
  }

  return (
    <>
      {array.map((element) => {
        const object = state[element];
        let isRange = false;

        if (object.range || (object.value && Array.isArray(object.value))) {
          isRange = true;
        }

        const { disabled, _static } = setDisabled(object, self);

        return (
          <FormDate
            key={element}
            onChange={self.handleFieldChange}
            item={object}
            loading={self.state.loading}
            disabled={disabled}
            _static={_static}
            isRange={isRange}
          />
        );
      })}
    </>
  );
};
