import msg from '../getErrorMsg';

export default (input, validationRules, context) => {
  const { value } = input;

  const { error, pattern, before, after } = validationRules;

  const inputValue = `${before + value.replace(/\s+/g, '') + after}`;

  const regexPattern = new RegExp(pattern.slice(1, -1));

  if (!regexPattern.test(inputValue)) {
    return msg(context, error);
  }

  return false;
};
