import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import api from 'API';
import { withNotificationForError } from './helper';

export const fetchAllCurrencies = createAsyncThunk(
  'currencies/fetchAllCurrencies',
  withNotificationForError(async (payload, { getState }) => {
    const userType = getState().userToken.tokenType;

    let CONFIG = api.banking.client.currenciesAll;

    if (userType === 'aemi') {
      CONFIG = api.banking.aemi.currenciesAll;
    }

    const response = await axios(CONFIG().AXIOS('get'));
    return response.data.data;
  })
);

const initialState = {
  loading: false,
  list: [],
  error: null,
};

const currenciesSlice = createSlice({
  name: 'currencies',
  initialState: initialState,
  reducers: {
    clearCurrencies() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCurrencies.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllCurrencies.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.list = action.payload;
      })
      .addCase(fetchAllCurrencies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

export const { clearCurrencies } = currenciesSlice.actions;
export default currenciesSlice.reducer;
