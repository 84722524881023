import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Row, Col } from 'antd';
import getFormData from 'get-form-data';

import { fieldChange, fieldValidation } from 'FIELDS';
import { InputsSet } from 'INPUTS';
import { tConsume } from 'TRANSLATION';
import defaults from 'DEFAULTS';

class TfaPhoneForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phone: {
        name: 'phone',
        value: '',
        placeholder: true,
        required: true,
        validation: { isPhone: true },
      },
    };

    this.handleFieldChange = fieldChange.bind(this, this);
    this.fieldValidation = fieldValidation.bind(this, this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { onConfirm } = this.props;
    const data = getFormData(event.target, { trim: true });
    if (!!data && this.fieldValidation()) {
      onConfirm({ data });
    }
  }

  render() {
    const { loading, t } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={20}>
          <Col>{InputsSet(this, ['phone'])}</Col>
          <Button
            size={defaults.antd.button.size}
            loading={loading}
            htmlType={'submit'}
            type={'primary'}
            block
          >
            {t('button.confirm')}
          </Button>
        </Row>
      </Form>
    );
  }
}

export default tConsume(TfaPhoneForm);

TfaPhoneForm.propTypes = {
  loading: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

TfaPhoneForm.defaultProps = {
  loading: false,
};
