/**
 *
 * @param {*} value
 * @returns {bool}
 *
 */
export default function (value) {
  let _value = value;

  if (typeof _value === 'string') {
    if (!isNaN(parseInt(_value))) {
      _value = parseInt(_value);
    }

    if (_value === 'false') {
      _value = false;
    }
  }

  return !_value ? 0 : 1;
}
