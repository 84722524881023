import React, { Component } from 'react';
import PropTypes from 'prop-types';
import anim_types from './../type';
import anim_func from './funcs';
import { TransitionGroup } from 'react-transition-group';
import defaults from 'DEFAULTS';

export default class AnimationGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchAnim: true,
      offsets: [],
      keys: [],
      children_count: 0,
    };
  }

  componentDidMount() {
    this.calcOffsets();
  }

  componentDidUpdate(prevProps) {
    const { keys } = this.state;

    if (this.diffKeys(keys, this.getKeysList(prevProps.children))) {
      this.setState(
        {
          fetchAnim: true,
        },
        () => {
          this.calcOffsets();
        }
      );
    }
  }

  getKeysList(children) {
    return React.Children.map(children, (item) => item.key);
  }

  diffKeys(keys1, keys2) {
    return keys1.length !== keys2.length || keys1.some((item, i) => item !== keys2[i]);
  }

  calcOffsets() {
    const { start, delay, children, type } = this.props;

    const keys = this.getKeysList(children);
    const count = React.Children.count(children);
    const params = this.typeParse(type);
    const offsets = anim_func({
      ...params,
      per_row: this.calcPerRow(),
      count,
      start,
      delay,
    });

    this.setState({
      keys,
      fetchAnim: false,
      offsets: offsets,
      children_count: count,
    });
  }

  calcPerRow() {
    const { per_row, grid } = this.props;

    if (per_row) {
      return per_row;
    }

    if (grid && Object.keys(grid).length) {
      const antd_grid = defaults.antd.grid.points;
      const antd_grid_size = defaults.antd.grid.size;
      const grid_point = defaults.antd.grid.getActivePoint();
      let per_grid_row = null;

      if (grid_point in grid) {
        per_grid_row = antd_grid_size / grid[grid_point];
      }

      if (per_grid_row === null) {
        let grid_index = null;

        antd_grid.filter(({ size }, i) => {
          if (size == grid_point) {
            grid_index = i;
          }
        });

        if (grid_index !== null) {
          antd_grid
            .slice(0, grid_index)
            .reverse()
            .forEach(({ size }) => {
              if (size in grid) {
                per_grid_row = antd_grid_size / grid[size];
              }
            });
        }
      }

      if (per_grid_row !== null) {
        return per_grid_row;
      }
    }

    return 1;
  }

  typeParse(type) {
    const parse = type.split('-');
    return {
      animation: parse[0],
      animation_direction: parse[1],
    };
  }

  render() {
    const { offsets, children_count, fetchAnim } = this.state;
    const { single_type, children } = this.props;

    if (fetchAnim) {
      return null;
    }

    const AnimType = anim_types[single_type];

    return (
      <TransitionGroup>
        {children_count &&
          React.Children.map(children, (Child, index) => {
            if (!offsets[index]) {
              return null;
            }

            const { offset, key } = offsets[index];
            return (
              <AnimType delay={offset} key={key}>
                {Child}
              </AnimType>
            );
          })}
      </TransitionGroup>
    );
  }
}

AnimationGroup.propTypes = {
  grid: PropTypes.object,
  per_row: PropTypes.number,
  type: PropTypes.string,
  start: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  delay: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  single_type: PropTypes.string,
};

AnimationGroup.defaultProps = {
  type: 'cascade',
  start: 0,
  delay: 100,
  single_type: 'fadedown',
};
