import cascade from './cascade';
import linear from './linear';

const anims = {
  cascade,
  linear,
};

export default function anim_funcs({ animation, ...params }) {
  return anims[animation](params);
}
