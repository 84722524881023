import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import api from 'API';
import { clearUserToken } from './userTokenSlice';
import { withNotificationForError } from './helper';

export const fetchAllPersonalData = createAsyncThunk(
  'personal/fetchAllPersonalData',
  withNotificationForError(async (payload, { getState }) => {
    const userType = getState().userToken.tokenType;

    const response = await axios(api.users.profile.personal(userType).AXIOS('get'));
    return response.data.data;
  })
);

const initialState = {
  loading: false,
  data: {},
  error: null,
};

const personalSlice = createSlice({
  name: 'personal',
  initialState: initialState,
  reducers: {
    getPersonalData(state, action) {
      state.data = action.payload;
    },
    clearPersonal() {
      return initialState;
    },
    changePersonalDataField(state, action) {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPersonalData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllPersonalData.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(fetchAllPersonalData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(clearUserToken, () => {
        return initialState;
      });
  },
});

export const { getPersonalData, clearPersonal, changePersonalDataField } = personalSlice.actions;
export default personalSlice.reducer;
