import msg from '../getErrorMsg';

export default (input, params, context) => {
  if (!params.some((item) => +context.state[item].value === 1)) {
    return msg(context, 'emptySet');
  }

  if (!input.value) {
    context.setState((state) => {
      state[input.name].value = true;
      state[input.name].status = '';
      state[input.name].help = '';
      return state;
    });
  }

  return false;
};
