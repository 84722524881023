import React from 'react';
import RandExp from 'randexp';
import { getTemplate } from 'TRANSLATION';
const Content = getTemplate('hint/PostalCode');

export default function postalCodeHint(data) {
  const rndPostalCode = new RandExp(new RegExp(data[0]));
  return <Content>{makeCodes(rndPostalCode)}</Content>;
}

function makeCodes(data) {
  const codes = [];

  for (let i = 0; i < 3; i++) {
    codes.push(data.gen());
  }

  return codes.map((item, index) => {
    return (
      <p
        key={`code${index}`}
        style={{ fontSize: 14, fontWeight: 400, marginBottom: 0, textAlign: 'center' }}
      >
        <strong>{item}</strong>
      </p>
    );
  });
}
