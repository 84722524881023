import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Row, Col } from 'antd';
import getFormData from 'get-form-data';

import FieldsGroup from '@atoms/form/fields-group';

import { fieldChange, fieldValidation, NUMBER_RULES } from 'FIELDS';
import { InputsSet } from 'INPUTS';
import { tConsume } from 'TRANSLATION';
import defaults from 'DEFAULTS';

class TfaPhoneForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tfa_code: {
        name: 'tfa_code',
        type: 'number',
        accept: defaults.re.digits,
        required: true,
        placeholder: true,
        label: props.t('field.code'),
        [NUMBER_RULES.LENGTH]: 4,
        autofocus: true,
      },
    };

    this.handleFieldChange = fieldChange.bind(this, this);
    this.fieldValidation = fieldValidation.bind(this, this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { onConfirm } = this.props;
    const data = getFormData(event.target, { trim: true });

    if (!!data && this.fieldValidation()) {
      onConfirm({ tfa_code: data.tfa_code });
    }
  }

  render() {
    const { loading, t } = this.props;
    const { tfa_code } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <FieldsGroup title={t('component.tfa.waiting_for_code')} />

        <Row gutter={20}>
          <Col sm={16}>{InputsSet(this, ['tfa_code'])}</Col>
          <Col sm={8}>
            <Button
              size={defaults.antd.button.size}
              loading={loading}
              htmlType={'submit'}
              type={'primary'}
              disabled={tfa_code.value?.length !== 4}
              block
            >
              {t('button.confirm')}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default tConsume(TfaPhoneForm);

TfaPhoneForm.propTypes = {
  loading: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

TfaPhoneForm.defaultProps = {
  loading: false,
};
