import { InputsSet } from 'INPUTS';

const FormContactInfo = (self, fields) => {
  const state = self.state;
  let _fields = [];

  if (fields && Array.isArray) {
    _fields = fields;
  }

  if (_fields.length == 0 && fields) {
    _fields = [fields.email, fields.phone];
  }

  if (!state || _fields.length == 0) {
    return null;
  }

  return InputsSet(self, [_fields[0], _fields[1]]);
};

export default FormContactInfo;
