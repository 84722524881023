import countryList from './countries.json';
import getUnique from '@common/helps/array/unique.js';
import getLangPart from './../../translation/getLangPart';
import transaltion from './../translation';
const locale_countries = getLangPart('countries', transaltion.lang);

export default getUnique(
  countryList.map((item) => {
    const country_code = item.code.trim().toUpperCase();

    const country = {
      key: item.key,
      country_code: country_code,
      country_title: item.title.trim(),
      phone_code: item.phone_code.trim(),
      title: locale_countries[country_code],
    };

    if (item.post_code_regex) {
      country.post_code_regex = item.post_code_regex;
    }

    if (item.phone_template) {
      country.phone_template = item.phone_template;
    }

    return country;
  }),
  'country_code'
);
