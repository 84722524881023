import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isObject from 'lodash.isobject';

import { Form, Radio } from 'antd';
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
import setLabel from '@atoms/form/funcs/setLabel';

import { tConsume } from 'TRANSLATION';
import defaults from 'DEFAULTS';

class FormRadio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      label: '',
      extra: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { t, item } = this.props;

    const label = setLabel(item, t);

    this.setState({
      ...item,
      extra: t(`field.${item.name}.extra`, false),
      label: label,
    });
  }

  /**
   *
   * Calls parrent change method
   * @param {*} value
   *
   */
  handleChange(value) {
    const { _static, item } = this.props;

    if (_static) {
      return;
    }

    const newValue = {
      ...item,
      value: value,
      help: '',
      status: '',
    };

    this.props.onChange(newValue);
  }

  renderRadio() {
    const { disabled, item, t } = this.props;
    const { value } = item;

    let _keys = [];
    if (item.keys && item.keys.length > 0) {
      _keys = item.keys;
    }

    if (_keys.length === 0) {
      _keys = t(`keys.${item.name}`, false);
    }

    return _keys.map((element) => {
      let _title = element,
        _value = element;

      if (isObject(element)) {
        _title = element.title;
        _value = element.value;
      }

      return (
        <RadioButton
          key={`${_value}-${_title}`}
          value={_value}
          disabled={disabled && _value !== value}
          style={{ flex: 1, textAlign: 'center' }}
        >
          {_title}
        </RadioButton>
      );
    });
  }

  render() {
    const { label, name } = this.state;
    const { loading, item, withLabel, _static } = this.props;
    const { value, status, help } = item;

    if (!item.keys || !item.keys.length) {
      return null;
    }

    const classes = [];

    if (_static) {
      classes.push('isStatic');
    }

    return (
      <Form.Item label={withLabel && label} validateStatus={status} help={help}>
        <RadioGroup
          className={classes.join(' ')}
          defaultValue={value}
          value={value}
          disabled={loading}
          onChange={(event) => this.handleChange(event.target.value)}
          buttonStyle="solid"
          size={defaults.antd.button.size}
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          {this.renderRadio()}
        </RadioGroup>

        <input type="hidden" name={name} value={value} />
      </Form.Item>
    );
  }
}

export default tConsume(FormRadio);

FormRadio.propTypes = {
  /**
    name:           string,
    value:          bool,
    status:         string,
    defaultValue:   string,
  */
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  withLabel: PropTypes.bool,
  _static: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

FormRadio.defaultProps = {
  disabled: false,
  loading: false,
  withLabel: true,
};
