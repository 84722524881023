import React from 'react';
import { Tooltip } from 'antd';

const tooltip = (icon, content) => {
  return (
    <Tooltip title={content} trigger="click" overlayClassName="HintTooltip">
      <span>{icon}</span>
    </Tooltip>
  );
};

export default tooltip;
