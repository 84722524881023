import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'antd';
import { Redirect } from 'react-router-dom';
import getFormData from 'get-form-data';
import axios from 'axios';

import notification from '@helps/notification';
import PasswordCriteriaList from '@atoms/PasswordCriteriaList/PasswordCriteriaList';
import Wrapper from '@molecules/wrappers/RobotWrapper';
import RegistrationBlockWrapper from '@molecules/wrappers/RegistrationBlockWrapper';

import api from 'API';
import { PasswordSet } from 'INPUTS';
import { fieldValidation, fieldBlur, responseError } from 'FIELDS';
import { tConsume } from 'TRANSLATION';
import defaults from 'DEFAULTS';

class PasswordReset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: 'passwordSetting',
      loading: false,
      redirect: false,
      validation: [],
      password: {
        name: 'password',
        type: 'password',
        autoComplete: 'new-password',
        required: true,
        validation: { isPassword: true },
        placeholder: true,
        disablePaste: true,
      },
      password_confirmation: {
        name: 'password_confirmation',
        type: 'password',
        autoComplete: 'new-password',
        required: true,
        placeholder: true,
        disablePaste: true,
      },
    };

    this._mounted = false;

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleFieldBlur = fieldBlur.bind(this, this);
    this.fieldValidation = fieldValidation.bind(this, this);
    this.responseError = responseError.bind(this, this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkboxFilling = this.checkboxFilling.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  /**
   *
   * Required by child component
   * @param {*} item
   *
   */
  handleFieldChange(item) {
    this.setState(
      (state) => {
        state[item.name] = {
          ...state[item.name],
          ...item,
        };
        return state;
      },
      () => this.checkboxFilling()
    );
  }

  /**
   *
   * @param {*} event
   *
   */
  handleSubmit(event) {
    event.preventDefault();

    const { t, userType, token } = this.props;

    const data = getFormData(event.target, { trim: true });

    // If all fields are correct
    // If the access code field on the page
    if (data.password === data.password_confirmation) {
      this.setState({
        loading: true,
      });

      data.token = token;

      axios(api.users.password(userType, 'reset').AXIOS('post', data))
        .then(() => {
          if (this._mounted) {
            sessionStorage.clear();

            notification(t('notification.passwordSuccess'), 'success');
            this.setState({ redirect: true });
          }
        })
        .catch((error) => {
          if (this._mounted) {
            this.responseError(error);
          }
        })
        .finally(() => {
          if (this._mounted) this.setState({ loading: false });
        });
    }
  }

  checkboxFilling() {
    const checkboxes = [];
    const lowerCase = new RegExp('[a-z]+');
    const upperCase = new RegExp('[A-Z]+');
    const number = new RegExp('[0-9]+');
    const longer = new RegExp('[^]{8,}?');

    const field1 = this.state.password.value;
    const field2 = this.state.password_confirmation.value;

    if (field1 && number.test(field1)) {
      checkboxes.push('specialChar');
    }

    if (field1 && lowerCase.test(field1)) {
      checkboxes.push('smallChar');
    }

    if (field1 && upperCase.test(field1)) {
      checkboxes.push('upperChar');
    }

    if (field1 && longer.test(field1)) {
      checkboxes.push('longer');
    }

    if (field1 && field2 && field1 === field2) {
      checkboxes.push('match');
    }

    this.setState({ validation: checkboxes });
  }

  render() {
    const { form, loading, validation, redirect } = this.state;
    const { t, redirectRoute, userType } = this.props;

    if (redirect) {
      return <Redirect push to={redirectRoute} />;
    }

    return (
      <Wrapper columns={false} sectionClass={'password-setting'}>
        <div className={'left-block'}>
          <RegistrationBlockWrapper userType={userType}>
            <Form id={form} onSubmit={this.handleSubmit} noValidate>
              <h1>{t('page.newPassword')}</h1>
              <p>{t('page.startUsing')}</p>

              <div className={'fields'}>
                {PasswordSet(this, ['password', 'password_confirmation'])}

                <PasswordCriteriaList
                  validation={validation}
                  nameOfClass={'right-block-common right-block-mobile'}
                  cyborg={false}
                />

                <Button
                  type={'primary'}
                  htmlType={'submit'}
                  size={defaults.antd.button.size}
                  loading={loading}
                  block
                >
                  {t('button.create')}
                </Button>
              </div>
            </Form>
          </RegistrationBlockWrapper>
        </div>

        <PasswordCriteriaList
          validation={validation}
          nameOfClass={'right-block-common right-block'}
          cyborg={defaults.variables.withTeidoWrapper}
        />
      </Wrapper>
    );
  }
}

export default tConsume(PasswordReset);

PasswordReset.propTypes = {
  userType: PropTypes.string.isRequired,
  redirectRoute: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};
