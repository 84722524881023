import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { setMenuConfig } from '@features/menuSlice';
import { fetchAllPersonalData } from '@features/personalSlice';
import { fetchAllAclData } from '@features/aclSlice';
import { fetchAllCurrencies } from '@features/currencySlice';
import Spinner from '@atoms/spinner';
import Sidebar from './Sidebar';
import Topbar from './Topbar';

const Wrapper = ({ userType, route, children }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(async () => {
    await getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      await dispatch(setMenuConfig());
      await dispatch(fetchAllPersonalData());
      await dispatch(fetchAllAclData());
      await dispatch(fetchAllCurrencies());
    } catch (e) {
      console.warn(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="dashboard-wrapper">
      <Sidebar userType={userType} route={route} />

      <div className="content">
        <Topbar userType={userType} route={route} />
        <section>{loading ? <Spinner /> : children}</section>
      </div>
    </div>
  );
};

export default Wrapper;

Wrapper.propTypes = {
  route: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};
