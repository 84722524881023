import { notification } from 'antd';
import isObject from 'lodash.isobject';
import { Strings } from 'HELPERS';

export default (text = {}, type = 'info') => {
  let message = '',
    description = '';

  if (isObject(text)) {
    if ('message' in text) {
      message = text.message;
    }

    if ('description' in text) {
      description = text.description;
    }
  }

  if (typeof text === 'string') {
    message = text;
  }

  const getOffset = () => {
    const headerSelector = document.querySelector('.dashboard-wrapper .header');

    return headerSelector ? headerSelector.getBoundingClientRect()?.height + 12 : 24;
  };

  notification.destroy();

  notification[type]({
    duration: 10,
    message: message || Strings.Capitalize(type),
    description: description,
    // header height + 12px offset
    top: getOffset(),
  });
};
