import merge from 'deepmerge';

/**
 *
 * @param {string} current link
 * @returns {bool} is localhost
 *
 */
const configs = merge(require('ROOT/configs.json'), require('ROOT/configs.prod.json'), {
  arrayMerge: (target, source) => [...new Set([...target, ...source])],
});

export default (hostname = window.location.hostname) => {
  const localhost = hostname === 'localhost';
  const dev = !!configs.dev;
  // [::1] is the IPv6 localhost address
  const IPv6 = hostname === '[::1]';
  // 192.0.0.1/8 is considered localhost for IPv4
  const IP = hostname.match(/^192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/);

  return localhost || dev || IPv6 || IP;
};
