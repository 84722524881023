import debounce from 'debounce';
import defaults from 'DEFAULTS';
import fieldValidation from './fieldValidation';

/**
 *
 * onBlur event handler
 * Required by child component
 * @param {*} self - context
 * @param {*} data - validated data
 *
 */
export default debounce((self, data, callback) => {
  const isValid = fieldValidation(self, data, false, false);

  if (!!callback && typeof callback === 'function') {
    callback(isValid, data);
  }
}, defaults.input.updateDelay);
