import config from './config';
import defaults from 'DEFAULTS';

export default (template) => {
  const path = `./${defaults.translation.lang}/`;
  const default_path = `./${defaults.translation.default_lang}/`;

  const template_base = config.template_base;

  let _template = tryRequire(`${path}${template_base}${template}.js`);
  if (!_template) {
    _template = tryRequire(`${default_path}${template_base}${template}.js`);
  }
  _template = _template.default || _template.Template;

  if (!_template) {
    throw 'Please provide default export or export as "Template" from translation template';
  }

  return _template;
};

function tryRequire(path) {
  let result = null;
  try {
    result = require(`${path}`);
  } catch (e) {
    if (e.code !== 'MODULE_NOT_FOUND') {
      throw e;
    }
  }
  return result;
}
