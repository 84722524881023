import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getFormData from 'get-form-data';
import axios from 'axios';

import { Button, Form } from 'antd';

import api from 'API';
import { fieldValidation, fieldChange, responseError } from 'FIELDS';
import { InputsSet, PasswordSet } from 'INPUTS';
import defaults from 'DEFAULTS';
import { tConsume } from 'TRANSLATION';

import EmailChange2step from '@organisms/personal-profile/EmailChange/EmailChange2step';

class EmailChange1step extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      form: 'email_change',
      waitingId: false,
      data: {},
      email: {
        name: 'email',
        type: 'email',
        placeholder: true,
        required: true,
        validation: { isEmail: true },
      },
      password: {
        name: 'password',
        type: 'password',
        autoComplete: 'current-password',
        required: true,
        validation: { isPassword: true },
        placeholder: true,
      },
    };

    this.handleFieldChange = fieldChange.bind(this, this);
    this.fieldValidation = fieldValidation.bind(this, this);
    this.responseError = responseError.bind(this, this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  // also in use in EmailChange2step for Resend confirmation code
  handleSubmit(event, data) {
    event.preventDefault();

    const { userType } = this.props;

    if (!data) {
      data = {
        ...this.state.data,
        ...getFormData(event.target, { trim: true }),
      };
    }

    if (this.fieldValidation()) {
      this.setState({ loading: true });

      axios(api.users.profile.changeEmailRequest(userType).AXIOS('POST', data))
        .then((response) => {
          if (this._mounted) {
            this.setState({
              waitingId: true,
              data: {
                ...data,
                id: response.data.id,
              },
            });
          }
        })
        .catch((error) => {
          if (this._mounted) {
            this.responseError(error);
          }
        })
        .finally(() => {
          if (this._mounted) this.setState({ loading: false });
        });
    }
  }

  render() {
    const { loading, waitingId, data } = this.state;
    const { t, userType } = this.props;

    if (waitingId) {
      return (
        <EmailChange2step
          userType={userType}
          data={data}
          toggleModal={this.props.toggleModal}
          confirmationCodeRequest={this.handleSubmit}
        />
      );
    }

    return (
      <Form onSubmit={this.handleSubmit}>
        <h3 className="align-center">{t('component.email.changeDescription')}</h3>

        {InputsSet(this, ['email'])}
        {PasswordSet(this, ['password'])}

        <Button
          type="primary"
          htmlType="submit"
          size={defaults.antd.button.size}
          loading={loading}
          block
        >
          {t('button.continue')}
        </Button>
      </Form>
    );
  }
}

export default tConsume(EmailChange1step);

EmailChange1step.propTypes = {
  userType: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
