import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';

import AddressChangeForm from './AddressChangeForm';

import { tConsume } from 'TRANSLATION';

export class AddressChange extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  toggleModal(visible = !this.state.visible) {
    this.setState({ visible: visible });

    if (!visible) {
      this.props.fetchAllPersonalData();
    }
  }

  render() {
    const { visible } = this.state;
    const { data, userType, t } = this.props;

    return (
      <>
        <Button type="dashed" shape="circle" icon="edit" onClick={() => this.toggleModal(true)} />

        <Modal
          visible={visible}
          destroyOnClose={true}
          maskClosable={false}
          title={t('component.actualAddress.change')}
          footer={null}
          onCancel={() => this.toggleModal(false)}
        >
          <AddressChangeForm data={data} userType={userType} toggleModal={this.toggleModal} />
        </Modal>
      </>
    );
  }
}

export default tConsume(AddressChange);

AddressChange.propTypes = {
  data: PropTypes.object.isRequired,
  userType: PropTypes.string.isRequired,
  fetchAllPersonalData: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
