// usage render: date => <FormatedDate date={date} yellowHours={168} greenHours={24} />

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { Tooltip, Badge } from 'antd';
import defaults from 'DEFAULTS';

const FormatDateRender = (props) => {
  const { date, yellowHours, greenHours } = props;

  const formatedDate = moment.tz(date, defaults.date.timeZone);
  const yellowDate = moment().subtract(yellowHours, 'hour');
  const greenDate = moment().subtract(greenHours, 'hour');

  let type = 'error';

  if (formatedDate.isAfter(yellowDate)) {
    type = 'warning';
  }

  if (formatedDate.isAfter(greenDate)) {
    type = 'success';
  }

  return (
    <Tooltip placement="top" title={formatedDate.calendar()}>
      <Badge status={type} text={formatedDate.startOf('seconds').fromNow()} />
    </Tooltip>
  );
};

FormatDateRender.propTypes = {
  date: PropTypes.string.isRequired,
  yellowHours: PropTypes.number,
  greenHours: PropTypes.number,
};

FormatDateRender.defaultProps = {
  yellowHours: 36,
  greenHours: 8,
};

export default FormatDateRender;
