import React, { Component } from 'react';
import PropTypes from 'prop-types';
import parseBool from '@common/helps/parseBool';
import { tConsume } from 'TRANSLATION';

import setLabel from '@atoms/form/funcs/setLabel';
import { Row, Col, Form, Switch, Icon } from 'antd';

class FormSwitch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      label: '',
      extra: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { t, item } = this.props;
    const { texts, extra, value } = item;

    const label = setLabel(item, t);

    this.setState({
      ...item,
      value: parseBool(value),
      extra: (texts && extra) || '',
      label: label,
    });

    this.handleChange(value);
  }

  /**
   *
   * Calls parrent change method
   * @param {*} value
   *
   */
  handleChange(value) {
    const { _static, item } = this.props;

    if (_static) {
      return;
    }

    const newValue = {
      name: item.name,
      value: parseBool(value),
      help: '',
      status: '',
    };

    this.props.onChange(newValue);
  }

  render() {
    const { label, extra, name } = this.state;
    const { disabled, loading, item, _static } = this.props;
    const { value, status, help } = item;

    return (
      <Row type="flex" justify="start" className="switchSet">
        <Col>{label}</Col>
        <Col>
          <Form.Item help={help} extra={extra} validateStatus={status}>
            <Switch
              checkedChildren={<Icon type="check" />}
              unCheckedChildren={<Icon type="close" />}
              checked={!!parseBool(value)}
              onChange={this.handleChange}
              disabled={_static || disabled || loading}
            />

            <input type="hidden" name={name} value={parseBool(value)} />
          </Form.Item>
        </Col>
      </Row>
    );
  }
}

export default tConsume(FormSwitch);

FormSwitch.propTypes = {
  /**
    name:           string,
    value:          bool,
    status:         string,
    defaultValue:   string,
  */
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  _static: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

FormSwitch.defaultProps = {
  disabled: false,
  loading: false,
};
