import React from 'react';
import FormInput from '@atoms/form/form-item/FormInput';
import setDisabled from '@atoms/form/funcs/setDisabled';
import defaults from 'DEFAULTS';

export default (self = {}, array = []) => {
  const state = self.state;

  if (!state) {
    return null;
  }

  return (
    <>
      {array.map((element) => {
        let object = { ...state[element] };

        const { disabled, _static } = setDisabled(object, self);

        let code = '';
        if ('validation' in object && object.validation.isPhone) {
          const code_name = object.name ? `country_${object.name}_code` : false;

          if (code_name && code_name in state && state[code_name].value) {
            code = defaults.location.phoneCode(state[code_name].value);
          }

          const addonBefore = 'addonBefore' in object ? object.addonBefore : {};

          object.addonBefore = {
            name: code_name,
            addonType: 'select',
            type: 'phone',
            width: 150,
            required: !!object.required,
            placeholder: false,
            init: true,
            value: code,
            ...addonBefore,
          };
        }

        let _hint = object.hint ? object.hint : null;
        if ('validation' in object && object.validation.isPostalCode) {
          let path = null;
          const param = object.validation.isPostalCode;

          if (param && param in state && state[param].value) {
            path = defaults.location.country.filter(
              (item) => item.country_code === state[param].value
            )[0];
          }

          if (path && path.post_code_regex) {
            _hint = { content: 'postalCode', data: [path.post_code_regex] };
          }
        }

        return (
          <FormInput
            key={element}
            onChange={self.handleFieldChange}
            onBlur={self.handleFieldBlur ? self.handleFieldBlur : false}
            item={object}
            loading={state.loading}
            disabled={disabled}
            validation={object.validation}
            withHint={_hint}
            _static={_static}
          />
        );
      })}
    </>
  );
};
