import msg from '../getErrorMsg';

export default (input, params, context) => {
  const sameAsValue = context.state[params].value;

  if (input.value !== sameAsValue) {
    return msg(context, 'passwordsNotMatch');
  }

  return false;
};
