import { getLangPart } from 'TRANSLATION';

export default (context, error) => {
  let msg = getLangPart('errors')[error];

  if (context && context.props && context.props.__tMounted) {
    msg = context.props.t(`errors.${error}`);
  }

  return msg;
};
