export function getDecimalsForCurrency(value, list) {
  let minLimit = 0;
  const foundCurr = list.find((curr) => curr.value === value);

  if (foundCurr && foundCurr.hasOwnProperty('decimals')) {
    minLimit = parseFloat(foundCurr.decimals);
  }

  return minLimit;
}
