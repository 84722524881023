import notification from '@helps/notification';
import merge from 'deepmerge';
import isObject from 'lodash.isobject';
import { rollbar } from '@common/rollbar';

export default function (self, error) {
  const _isResponse = isObject(error) && error.response;
  const _data = _isResponse && error.response.data && error.response.data.error;
  const _error = _data ? error.response.data.error : false;
  const _status = _isResponse ? error.response.status : false;

  if (!_error || !_status || _status >= 500) {
    let message = 'Service is unavailable';

    if (self && self.props && self.props.__tMounted) {
      message = self.props.t('notification.serviceUnavailable');
    }

    notification(message, 'error');
    return false;
  }

  if (_error) {
    if (self && typeof _error === 'object' && [422, 400].indexOf(_status) >= 0) {
      const fields = {};

      for (let key in _error) {
        const errorMessage = _error[key];

        if (key in self.state && self.state[key].name && !self.state[key].notField) {
          fields[key] = {
            status: 'error',
            help: typeof errorMessage === 'string' ? errorMessage : errorMessage[0],
          };
        } else {
          notification({ message: errorMessage }, 'error');
        }
      }

      self.setState({ ...merge(self.state, fields) });
    }

    if (typeof _error === 'string') {
      notification({ message: _error }, 'error');
    }

    rollbar.error(error);
    return true;
  }
}
