import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import FormDivider from '@atoms/form/FormDivider/FormDivider';
import ConfirmationWrapper from '@molecules/security/ConfirmationWrapper';

import api from 'API';
import { tConsume } from 'TRANSLATION';
import { Strings } from 'HELPERS';

const EmailChange3step = ({ userType, data, toggleModal, t, waitingCode }) => {
  return (
    <ConfirmationWrapper
      userType={userType}
      tfaMethod={waitingCode}
      toggleModal={toggleModal}
      data={data}
      link={api.users.profile.changeEmailVerify(userType)}
      textSuccess={
        <p className={'align-center'}>
          {`${Strings.Compile(t('component.email.tfaNoteSuccessDescription'), [data.email])} `}
        </p>
      }
      returnResult={true}
    >
      <Row className={'text-preview'}>
        <Col md={12}>
          <div className={'title'}>{t('component.email.tfaChangingMessage')}</div>
          <div className={'info'}>{data.email}</div>
        </Col>
      </Row>

      <FormDivider />
    </ConfirmationWrapper>
  );
};

export default tConsume(EmailChange3step);

EmailChange3step.propTypes = {
  userType: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  waitingCode: PropTypes.string,
  t: PropTypes.func.isRequired,
};
