const antd = () => {
  const grid_points = [
    { size: 'xs', value: 0 },
    { size: 'sm', value: 576 },
    { size: 'md', value: 768 },
    { size: 'lg', value: 992 },
    { size: 'xl', value: 1200 },
    { size: 'xxl', value: 1600 },
  ];

  const grid_size = 24;

  const getActivePoint = () => {
    const vw = window.innerWidth;
    let result = 'xs';
    for (let i = 1; i < grid_points.length; i++) {
      if (grid_points[i].value > vw) {
        break;
      }

      result = grid_points[i].size;
    }

    return result;
  };

  return {
    button: {
      size: 'large',
    },
    input: {
      size: 'large',
    },
    grid: {
      size: grid_size,
      points: grid_points,
      getActivePoint,
    },
  };
};

export default antd();
