export function getCheckboxAnswer({ value, t }) {
  let translationProp = 'button.notField';

  if (value !== undefined) {
    const valueIsPositive = value === '1' || value.toString().toUpperCase() === 'TRUE';
    translationProp = valueIsPositive ? 'button.yes' : 'button.no';
  }

  return t(translationProp);
}
