import React from 'react';
import PropTypes from 'prop-types';

import Cyborg from '@atoms/cyborg';

const RobotWrapper = ({ columns, sectionClass, children }) => (
  <section className={`auth-page registration ${sectionClass}`}>
    {columns && (
      <>
        <div className={'left-block'}>{children}</div>

        <div className={'right-block'}>
          <Cyborg />
        </div>
      </>
    )}

    {!columns && children}
  </section>
);

export default RobotWrapper;

RobotWrapper.propTypes = {
  columns: PropTypes.bool,
  sectionClass: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

RobotWrapper.defaultProps = {
  columns: true,
  sectionClass: '',
};
