/**
 * https://github.com/tjcafferkey/stringinject
 */
export default function (str, replace) {
  if (typeof str === 'string') {
    if (replace instanceof Array) {
      return str.replace(/({\d})/g, function (i) {
        return replace[i.replace(/{/, '').replace(/}/, '')];
      });
    }

    if (replace instanceof Object) {
      if (!Object.keys(replace).length) {
        return str;
      }

      for (let key in replace) {
        return str.replace(/({([^}]+)})/g, function (i) {
          let key = i.replace(/{/, '').replace(/}/, '');

          if (!replace[key]) {
            return i;
          }

          return replace[key];
        });
      }
    }

    if (replace instanceof Array === false || replace instanceof Object === false) {
      return str;
    }
  }

  return false;
}
