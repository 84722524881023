import USPerson from './USPerson';
import PEP from './PEP';
import UBO from './UBO';
import FIS from './FIS';
import postalCode from './postalCode';
import TFAGoogle from './TFAGoogle';
import utilityBill from './utilityBill';
import passphrase from './passphrase';
import professionalStatusCertification from './professionalStatusCertification';
import proofOfResidence from './proofOfResidence';
import shareholderDocuments from './shareholderDocuments';
import highRiskIndustries from './highRiskIndustries';

export default {
  USPerson,
  PEP,
  UBO,
  FIS,
  postalCode,
  TFAGoogle,
  utilityBill,
  passphrase,
  professionalStatusCertification,
  proofOfResidence,
  shareholderDocuments,
  highRiskIndustries,
};
