/**
 * Expanded part of table Transactions
 */
import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';

import { Avatar } from 'antd';

import defaults from 'DEFAULTS';

export default (item) => {
  return defaults.currency.types.map((currency) => {
    const currencySymbol = getSymbolFromCurrency(currency.value);
    const sm = item && item.width && parseInt(item.width) < 250 ? 'sm' : '';

    return {
      title: (
        <div className={`payment-select ${sm}`}>
          <div>
            <Avatar size="small">{currencySymbol}</Avatar>
            <strong>{currency.value}</strong>
          </div>

          <span className="no-display-phone">{currency.title}</span>
        </div>
      ),
      value: currency.value,
    };
  });
};
