/**
 * Usage:
    <Row gutter={20} className="text-preview">
      {RenderPreviewItem(t('field.company_name'), company_name.value, 24)}
    </Row>
    Note: className="text-preview" in Row
 */

import React from 'react';
import { Col } from 'antd';
import { prepareDataForPreview } from '@helps/prepareDataForPreview';

const RenderPreviewItem = (label = '', value = '', column = 12, long_data = false) => {
  const { isValueValid, _value, _label } = prepareDataForPreview({ value, label });

  if (!isValueValid) {
    return null;
  }
  return (
    <Col md={column} key={Math.random()}>
      <div className="title">
        {_label}
        {_label[_label.length - 1] !== ':' && ':'}
      </div>
      <div className={`${long_data ? 'long ' : ''}info`}>{_value}</div>
    </Col>
  );
};

export default RenderPreviewItem;
