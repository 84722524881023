import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// import { Provider as RollbarProvider } from '@rollbar/react';
import { store, persistor } from 'STORE';
import { render } from 'react-dom';

import defaults from 'DEFAULTS';
import { rollbar } from '@common/rollbar';

import IndexPage from './router/index';
import * as serviceWorker from './serviceWorker';

import 'STYLES/styles.less';
import './common/additional-scripts';

window.$rollbar = rollbar;
window.__CONFIGURATION__ = defaults;

render(
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <IndexPage />
    </PersistGate>
  </ReduxProvider>,
  document.getElementById('content')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
