//TYPES
import phone from './type/phone';
import country from './type/country';
import currency from './type/currency';

export const SelectTypes = {
  phone,
  country,
  currency,
};
