import React from 'react';
import PropTypes from 'prop-types';

import { Strings } from 'HELPERS';
import { tConsume } from 'TRANSLATION';
import defaults from 'DEFAULTS';

function ConditionsBlock({ t }) {
  const str = t('component.conditions.tpl');
  const { terms_and_conditions, privacy_policy, home, agent, title } = defaults.website;

  if (!terms_and_conditions || !privacy_policy) {
    return null;
  }

  const replace = [
    <a key={0} href={terms_and_conditions} target={'_blank'}>
      {t('component.conditions.conditions0')}
    </a>,
    <a key={1} href={privacy_policy} target={'_blank'}>
      {t('component.conditions.conditions1')}
    </a>,
    <a
      key={2}
      href={home.protocol && home.host ? `${home.protocol}://${home.host}` : '/'}
      target={'_blank'}
    >
      {agent || title}
    </a>,
  ];

  return (
    <div className={'conditions-block'}>
      <p>
        <Strings.CompileComponent str={str} replace={replace} />
      </p>
    </div>
  );
}

export default tConsume(ConditionsBlock);

ConditionsBlock.propTypes = {
  t: PropTypes.func.isRequired,
};
