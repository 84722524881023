import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';

import ContentWrapper from '@atoms/content-wrapper';
import FieldsGroup from '@atoms/form/fields-group';
import { TfaMethod } from '@organisms/personal-profile/ProfileTfa';

import { tConsume } from 'TRANSLATION';

const ProfileTfa = ({ personalData, userType, t }) => {
  const [isActiveMethodSingle, setIsActiveMethodSingle] = useState(true);
  const methods = t('keys.tfa');

  useEffect(() => {
    let activeMethodsCount = 0;
    methods.forEach((method) => {
      if (personalData[method.value] === 1) activeMethodsCount++;
    });

    setIsActiveMethodSingle(activeMethodsCount <= 1);
  }, [personalData]);

  return (
    <ContentWrapper>
      <FieldsGroup header={t('fieldsGroup.tfa.title')}>
        {methods.map((method, index) => {
          const isActiveMethod = personalData[method.value] === 1;
          const isPinnedMethod = personalData.preferred_2fa === method.short_value;

          return (
            <Fragment key={method.value}>
              {index === 0 && <Divider className={'tfaItem_divider'} />}
              <TfaMethod
                method={method}
                isPinned={isPinnedMethod}
                isActive={isActiveMethod}
                isActiveMethodSingle={isActiveMethodSingle}
                userType={userType}
                t={t}
              />
              <Divider className={'tfaItem_divider'} />
            </Fragment>
          );
        })}
      </FieldsGroup>
    </ContentWrapper>
  );
};

export default tConsume(ProfileTfa);

ProfileTfa.propTypes = {
  personalData: PropTypes.object.isRequired,
  userType: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};
