import defaults from 'DEFAULTS';
import { getLangPart } from 'TRANSLATION';
import { Strings } from 'HELPERS';
import { STRING_RULES } from 'FIELDS';

const checkIsMountedAndHasContext = (context) =>
  Boolean(context && context.props && context.props.__tMounted);

export default (input, params, context) => {
  let min = defaults.input.minLength;

  if (input.hasOwnProperty(STRING_RULES.MIN)) {
    min = input.min;
  }

  if (input.value.trim().length < min) {
    if (checkIsMountedAndHasContext(context)) {
      return Strings.Compile(context.props.t('errors.tooShort'), [min]);
    }

    return Strings.Compile(getLangPart('errors').tooShort, [min]);
  }

  if (input.hasOwnProperty(STRING_RULES.MAX)) {
    if (input.value.trim().length > input[STRING_RULES.MAX]) {
      if (checkIsMountedAndHasContext(context)) {
        return Strings.Compile(
          context.props.t(input.maxLengthError ? input.maxLengthError.message : 'errors.tooLarge'),
          [input.maxLengthError ? input.maxLengthError.length : input[STRING_RULES.MAX]]
        );
      }

      return Strings.Compile(getLangPart('errors').tooShort, [input[STRING_RULES.MAX]]);
    }
  }

  return false;
};
