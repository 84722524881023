/**
 *
 * @param {ReactDOM, array} self (if array - [
 *    @param {ReactDOM} self (required),
 *    @param {string} getData - get data func name (optional)
 *    @param {string} filters - filters object name in state (optional)
 * ])
 * @param {*} pagination
 * @param {*} filters
 * @param {*} column
 *
 */
export default (self, pagination = {}, f, column = {}) => {
  let getData, filters;

  if (Array.isArray(self)) {
    getData = self[1];
    filters = self[2];
    self = self[0];
  }

  if (!getData) {
    getData = 'getData';
  }

  if (!filters) {
    filters = 'active_filters';
  }

  if (!getData || !self[getData] || typeof self[getData] !== 'function') {
    return false;
  }

  let sharedFilters = filters && self.state[filters] ? { ...self.state.active_filters } : {};

  for (let item in sharedFilters) {
    sharedFilters[item] = sharedFilters[item].toString();
  }

  sharedFilters.sort = column.order === 'descend' ? '-' : '';
  sharedFilters.page = pagination.current;
  sharedFilters.sort += column.field || '';

  self[getData](sharedFilters);
};
