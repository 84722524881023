import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Strings } from 'HELPERS';
import { tConsume } from 'TRANSLATION';

class PhoneChangeSuccess extends Component {
  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    const { t, data } = this.props;

    return (
      <p className="align-center">
        <Strings.CompileComponent
          str={t('component.phoneNumber.tfaNoteSuccessDescription')}
          replace={[<strong key="phone">{`+${data.country_phone_code} ${data.phone}`}</strong>]}
        />
      </p>
    );
  }
}

export default tConsume(PhoneChangeSuccess);

PhoneChangeSuccess.propTypes = {
  data: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};
