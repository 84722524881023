import React from 'react';

export default function Template(props) {
  return (
    <div>
      <h3>Post codes in this country may have the format as follows:</h3>
      {props.children}
      <p>note: Post codes are shown as an example. Do not use them.</p>
    </div>
  );
}
