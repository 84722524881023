import msg from '../getErrorMsg';
import defaults from 'DEFAULTS';

export default (input, params, context) => {
  if (!defaults.re.cryptoCurrency.test(input.value)) {
    return msg(context, 'notCryptoCurrency');
  }

  return false;
};
