import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import api from 'API';
import { clearUserToken } from './userTokenSlice';
import { withNotificationForError } from './helper';

export const fetchAllAclData = createAsyncThunk(
  'acl/fetchAllAclData',
  withNotificationForError(async (payload, { getState }) => {
    const id = getState().personal.data.user_id;
    const userType = getState().userToken.tokenType;

    const response = await axios(api.users.permissions(id, userType).AXIOS('get'));
    return response.data.map((item) => item.name);
  }),
  {
    condition: (payload, { getState }) => {
      const id = getState().personal.data.user_id;
      const userType = getState().userToken.tokenType;

      if (!id || !userType) return false;
    },
  }
);

const initialState = {
  loading: false,
  data: {
    acl: [],
  },
  error: null,
};

const aclSlice = createSlice({
  name: 'acl',
  initialState: initialState,
  reducers: {
    clearAcl() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAclData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllAclData.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data.acl = action.payload;
      })
      .addCase(fetchAllAclData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(clearUserToken, () => {
        return initialState;
      });
  },
});

export const { clearAcl } = aclSlice.actions;
export default aclSlice.reducer;
