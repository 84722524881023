import isObject from 'lodash.isobject';
import isNil from 'lodash.isnil';

export default (array, _value) => {
  if (!array || !Array.isArray(array) || isNil(_value)) {
    return {};
  }

  let value = _value;
  let key = 'value';

  if (isObject(value)) {
    key = Object.keys(value)[0];
    value = value[key];
  }

  return array.filter((item) => item[key] === value)[0] || {};
};
