import React from 'react';
import { Row, Col } from 'antd';

import { InputsSet, SelectSet } from 'INPUTS';

export default (self, fields) => {
  const state = self.state;
  let _fields = [];

  if (fields && Array.isArray) {
    _fields = fields;
  }

  if (_fields.length === 0 && fields) {
    _fields = [fields.country, fields.city, fields.post_code, fields.address1, fields.address2];
  }

  if (!state || _fields.length === 0) {
    return null;
  }

  return (
    <>
      {SelectSet(self, [_fields[0]])}
      <Row gutter={10}>
        <Col md={12}>{InputsSet(self, [_fields[1]])}</Col>
        <Col md={12}>{InputsSet(self, [_fields[2]])}</Col>
      </Row>
      {InputsSet(self, [_fields[3]])}
      {_fields[4] && InputsSet(self, [_fields[4]])}
    </>
  );
};
