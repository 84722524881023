import React, { Component } from 'react';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { rollbar } from '@common/rollbar';

import RouteProcessor from './routeProcessor';
import { tProvide } from 'TRANSLATION';
import defaults from 'DEFAULTS';

class IndexPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidCatch(error) {
    rollbar.error(error);
  }

  render() {
    const { AUTHORIZED, VERIFIED } = this.props;
    const initialRedirect = `/${defaults.variables.account_types[1]}`;

    return (
      <BrowserRouter>
        <Switch>
          <Redirect exact from="/" to={initialRedirect} />

          <Route
            path="/:userType"
            render={(match) => (
              <RouteProcessor AUTHORIZED={AUTHORIZED} VERIFIED={VERIFIED} match={match.match} />
            )}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userToken: state.userToken,
    AUTHORIZED: state.userToken.token !== null,
    VERIFIED: state.userToken.status,
  };
};

export default tProvide(connect(mapStateToProps)(IndexPage));

IndexPage.propTypes = {
  userToken: PropTypes.object,
  AUTHORIZED: PropTypes.bool.isRequired,
  VERIFIED: PropTypes.bool.isRequired,
};

IndexPage.defaultProps = {
  VERIFIED: false,
};
