import msg from '../getErrorMsg';
import defaults from 'DEFAULTS';

export default (input, params, context) => {
  let regexp = false;
  let path = null;
  const state = context.state;

  if (!params) {
    return false;
  }

  if (params in state && state[params].value) {
    path = defaults.location.country.filter((item) => item.country_code === state[params].value)[0];
  }

  if (path && path.post_code_regex) {
    regexp = path.post_code_regex;
  }

  if (regexp && !new RegExp(regexp, 'i').test(input.value.trim())) {
    return msg(context, 'postalCode');
  }

  return false;
};
