import isAlpha from 'validator/lib/isAlpha';
import msg from '../getErrorMsg';

export default (input, params, context) => {
  const ignore = `${params.ignore}1234567890`; // Игнорируемые символы
  let value = input.value;
  const regex = new RegExp(`[${ignore}]`, 'g');
  value = value.replace(regex, '');
  if (value.length === 0) {
    return false;
  }
  if (!input.value) {
    return false;
  }
  if (!isAlpha(input.value, params.locale, { ignore: ignore })) {
    return msg(context, 'notAlphaAndNumbersAndSpecialCharacters');
  }

  return false;
};
