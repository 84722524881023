const company = [
  { step: 'business_complete', route: 'profile', order: 1 },
  { step: 'operational_complete', route: 'operational', order: 2 },
  { step: 'directors_complete', route: 'directors', order: 3 },
  { step: 'staffs_complete', route: 'representatives', order: 4 },
  { step: 'shareholders_complete', route: 'shareholders', order: 5 },
  {
    step: 'status_certification_complete',
    route: 'status_certification',
    order: 6,
    enabled: 'status_certification_enabled',
  },
  { step: 'confirmation_link_complete', route: 'finish', order: 7 },
  { step: 'jumio_complete', route: 'jumio', order: 8, enabled: 'jumio_enabled' },
  { step: 'jumio_directors_complete', route: 'onboarding', order: 9, enabled: 'jumio_enabled' },
  { step: 'sumsub_complete', route: 'sumsub', order: 8, enabled: 'sumsub_enabled' },
  { step: 'cs_complete', route: 'inprogress', order: 10 },
  { step: 'ca_complete', route: 'inprogress', order: 10 },
];

const individual = [
  { step: 'business_complete', route: 'profile', order: 1 },
  {
    step: 'business_activity_complete',
    route: 'business_activity',
    order: 2,
    enabled: 'business_activity_enabled',
  },
  {
    step: 'status_certification_complete',
    route: 'status_certification',
    order: 3,
    enabled: 'status_certification_enabled',
  },
  { step: 'confirmation_link_complete', route: 'finish', order: 4 },
  { step: 'jumio_complete', route: 'jumio', order: 5, enabled: 'jumio_enabled' },
  { step: 'jumio_directors_complete', route: 'onboarding', order: 6, enabled: 'jumio_enabled' },
  { step: 'sumsub_complete', route: 'sumsub', order: 5, enabled: 'sumsub_enabled' },
  { step: 'cs_complete', route: 'inprogress', order: 7 },
  { step: 'ca_complete', route: 'inprogress', order: 7 },
];

export default {
  company,
  individual,
};
