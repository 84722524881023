import React from 'react';
import FormRadio from '@atoms/form/form-item/FormRadio';
import InputRow from '@atoms/form/input-row';
import { Form } from 'antd';
import setLabel from '@atoms/form/funcs/setLabel';
import setDisabled from '@atoms/form/funcs/setDisabled';

export default (self = {}, array = [], row = false, validation = false) => {
  const state = self.state;

  if (!state) {
    return null;
  }

  return (
    <>
      {array.map((element) => {
        const object = state[element];

        if (object) {
          const { disabled, _static } = setDisabled(object, self);

          const label = setLabel(object, self.props && self.props.t);

          if (row) {
            return (
              <InputRow
                key={element}
                label={label}
                flex={{ justify: 'start' }}
                style={{ padding: 0 }}
              >
                <FormRadio
                  item={object}
                  withLabel={false}
                  onChange={self.handleFieldChange}
                  loading={self.state.loading}
                  disabled={disabled}
                  _static={_static}
                />
              </InputRow>
            );
          }

          return (
            <FormRadio
              key={element}
              item={object}
              onChange={self.handleFieldChange}
              loading={self.state.loading}
              disabled={disabled}
              _static={_static}
            />
          );
        }

        return <React.Fragment key={element} />;
      })}

      {!('_static' in self.state && self.state._static) && validation && row && (
        <Form.Item
          style={{ marginBottom: 0 }}
          help={state[validation].help}
          validateStatus={state[validation].status}
        />
      )}
    </>
  );
};
