import React, { Component } from 'react';
import PropTypes from 'prop-types';
import api from 'API';
import axios from 'axios';
import getFormData from 'get-form-data';
import { Button, Form, Alert } from 'antd';

import { getFields, fieldValidation, fieldChange, responseError, MAX_TEXT_LENGTH } from 'FIELDS';
import { FormAddress } from '@common/forms';
import notification from '@helps/notification';
import { tConsume } from 'TRANSLATION';
import defaults from 'DEFAULTS';

class AddressChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      form: 'actual_address_change',
      country: {
        name: 'country',
        type: 'country',
        required: true,
        placeholder: true,
      },
      post_code: {
        name: 'post_code',
        type: 'text',
        required: true,
        max: MAX_TEXT_LENGTH.TWENTY,
        placeholder: true,
        validation: { isPostalCode: 'country' },
      },
      city: {
        name: 'city',
        type: 'text',
        required: true,
        max: MAX_TEXT_LENGTH.FIFTY,
        placeholder: true,
      },
      address1: {
        name: 'address1',
        type: 'text',
        required: true,
        max: MAX_TEXT_LENGTH.ONE_HUNDRED,
        placeholder: true,
      },
      address2: {
        name: 'address2',
        type: 'text',
        max: MAX_TEXT_LENGTH.ONE_HUNDRED,
        placeholder: true,
      },
    };

    this.getFields = getFields.bind(this, this);
    this.handleFieldChange = fieldChange.bind(this, this);
    this.fieldValidation = fieldValidation.bind(this, this);
    this.responseError = responseError.bind(this, this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this._mounted = true;

    const { data } = this.props;
    const _data = data && data.new_actual_address ? data.new_actual_address : data;
    const disabledFieldsList = ['country', 'post_code', 'city', 'address1', 'address2'];

    this.getFields(
      _data,
      false,
      (item) => disabledFieldsList.indexOf(item.name) >= 0 && !!data.new_actual_address
    );
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleSubmit(event) {
    event.preventDefault();

    const { userType, toggleModal, t } = this.props;
    const data = getFormData(event.target, { trim: true });

    if (this.fieldValidation()) {
      this.setState({ loading: true });
      axios(api.users.profile.changeActualAddress(userType).AXIOS('PUT', data))
        .then(() => {
          if (this._mounted) {
            notification(t('component.actualAddress.successDescription'), 'success');
            toggleModal(false);
          }
        })
        .catch((error) => {
          if (this._mounted) {
            this.responseError(error);
          }
        })
        .finally(() => {
          if (this._mounted) this.setState({ loading: false });
        });
    }
  }

  render() {
    const { form, loading } = this.state;
    const { data, t } = this.props;
    const { new_actual_address } = data;

    return (
      <Form onSubmit={this.handleSubmit} id={form}>
        <Alert
          type={new_actual_address ? 'warning' : 'info'}
          className="mb20 align-center"
          message={t(
            `component.actualAddress.${
              new_actual_address ? 'changingDescription' : 'changeDescription'
            }`
          )}
        />

        {FormAddress(this, ['country', 'city', 'post_code', 'address1', 'address2'])}

        <Button
          type="primary"
          htmlType="submit"
          size={defaults.antd.button.size}
          loading={loading}
          block
          disabled={!!new_actual_address}
        >
          {t('button.continue')}
        </Button>
      </Form>
    );
  }
}

export default tConsume(AddressChangeForm);

AddressChangeForm.propTypes = {
  data: PropTypes.object.isRequired,
  userType: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
