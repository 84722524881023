import config from './config';
import defaults from 'DEFAULTS';
import merge from 'deepmerge';
import isObject from 'lodash.isobject';

export default (page) => {
  const lang = defaults.translation.lang;
  const default_lang = defaults.translation.default_lang;

  const result = compareTranslations(lang, page);

  if (default_lang !== lang) {
    return merge(compareTranslations(default_lang, page), result, { arrayMerge });
  }

  return result;
};

const arrayMerge = (a, b) => {
  let result = [];
  if (!a || !a.length) {
    result = b;
  } else {
    if (isObject(b[0]) && 'value' in b[0]) {
      result = a.map((itema) => {
        const item = b.filter((itemb) => itemb.value === itema.value)[0];
        return { ...itema, ...(item ? { title: item.title } : {}) };
      });
    }
  }

  return result;
};

const compareTranslations = (lang, page) => {
  let result = {};
  let page_translation = {};
  const list = config.parts;
  const page_base = config.page_base;
  const path = `./${lang}/`;

  list.forEach((part) => {
    result[part] = tryRequire(`${path}${part}.json`);
  });

  if (page) {
    if ((Array.isArray(page) && page.length == 0) || typeof page === 'string') {
      let _page = Array.isArray(page) ? page[0] : page;
      page_translation = tryRequire(`${path}${page_base}${_page}.json`);
    }

    if (Array.isArray(page) && page.length > 1) {
      page.forEach((item) => {
        page_translation = merge(page_translation, tryRequire(`${path}${page_base}${item}.json`));
      });
    }
  }

  return page_translation.page ? merge(result, page_translation) : result;
};

function tryRequire(path) {
  let result = {};
  try {
    result = require(`${path}`);
  } catch (e) {
    if (e.code !== 'MODULE_NOT_FOUND') {
      throw e;
    }
  }
  return result;
}
