import CompileComponent from './compile-component';
import Compile from './compile-string';
import Capitalize from './capitalize';

export default {
  CompileComponent(...rest) {
    return CompileComponent(...rest);
  },

  Compile(...rest) {
    return Compile(...rest);
  },

  Capitalize,
};
