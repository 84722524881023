import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getFormData from 'get-form-data';
import axios from 'axios';

import { fieldValidation, fieldChange, responseError, MAX_TEXT_LENGTH } from 'FIELDS';
import { InputsSet } from 'INPUTS';
import { tConsume } from 'TRANSLATION';
import api from 'API';
import defaults from 'DEFAULTS';

import { Form, Button } from 'antd';

import notification from '@helps/notification';
import FieldsGroup from '@atoms/form/fields-group';
import ContentWrapper from '@atoms/content-wrapper';
import Hint from '@molecules/hint';

class Passphrase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      form: 'passphraseChange',
      passphrase: {
        name: 'passphrase',
        placeholder: true,
        required: true,
        max: MAX_TEXT_LENGTH.FIFTY,
      },
    };

    this.handleFieldChange = fieldChange.bind(this, this);
    this.fieldValidation = fieldValidation.bind(this, this);
    this.responseError = responseError.bind(this, this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleSubmit(event) {
    event.preventDefault();
    const { userType, t } = this.props;

    const data = getFormData(event.target, { trim: true });

    if (this.fieldValidation()) {
      this.setState({
        loading: true,
        data,
      });

      axios(api.users.profile.passphrase(userType).AXIOS('post', data))
        .then(() => {
          notification(t('notification.passphraseSuccess'), 'success');
        })
        .catch((error) => {
          if (this._mounted) {
            this.responseError(error);
          }
        })
        .finally(() => {
          if (this._mounted) this.setState({ loading: false });
        });
    }
  }

  render() {
    const { form, loading } = this.state;
    const { t } = this.props;

    return (
      <ContentWrapper>
        <Form
          id={form}
          onSubmit={this.handleSubmit}
          className="authorization-page section form-large payment-form"
        >
          <FieldsGroup
            title={
              <>
                {t('field.passphrase.label')}
                <Hint content="passphrase" type="popup" />
              </>
            }
          >
            {InputsSet(this, ['passphrase'])}

            <Button
              type="primary"
              htmlType="submit"
              size={defaults.antd.button.size}
              loading={loading}
              block
            >
              {t('button.continue')}
            </Button>
          </FieldsGroup>
        </Form>
      </ContentWrapper>
    );
  }
}

export default tConsume(Passphrase);

Passphrase.propTypes = {
  userType: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};
