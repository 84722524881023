import React from 'react';
import PropTypes from 'prop-types';

export default function CompileComponent(props) {
  const { str, replace } = props;

  let result,
    tmp = str.split(/({\d})/g);
  if (tmp.length == 1) {
    result = str;
  } else {
    result = tmp.map((item) => {
      let res = item.match(/{(\d)}/);

      return Array.isArray(res) ? replace[parseInt(res[1])] : item;
    });
  }

  return <React.Fragment>{result}</React.Fragment>;
}

/**
 * @param  {} props
 * str: String with inserts for replacement "{number}". number - the sequence number of the replace array element.
 * replace: Array of replace elements.
 * EXAMPLE - str: 'hello {0}!', replace: ['world'] -> 'hello world!'
 */
CompileComponent.propTypes = {
  str: PropTypes.string.isRequired,
  replace: PropTypes.array.isRequired,
};
