import isObject from 'lodash.isobject';

const uniqueSimple = (arr) => {
  return arr.filter(function (item, i, self) {
    return self.indexOf(item) === i;
  });
};

const uniqueObj = (arr, prop) => {
  const matches = {};

  arr.forEach((item, i) => {
    if (!isObject(item) || !(prop in item)) {
      return false;
    }

    matches[item[prop]] = i;
  });

  return Object.values(matches).map((item) => arr[item]);
};

export default function (array, property) {
  if (!Array.isArray(array)) {
    return [];
  }

  return property ? uniqueObj(array, property) : uniqueSimple(array);
}
