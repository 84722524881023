export function getMaxLengthForCurrency(value, list) {
  let maxLength = 0;
  const foundCurr = list.find((curr) => curr.value === value);

  if (foundCurr && foundCurr.hasOwnProperty('length_limit')) {
    maxLength = parseFloat(foundCurr.length_limit);
  }

  return maxLength;
}
