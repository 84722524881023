import React from 'react';
import PropTypes from 'prop-types';

import { Badge } from 'antd';

const FormatUserLevelRender = ({
  status,
  greenStatus,
  yellowStatus,
  redStatus,
  burgundyStatus,
}) => {
  let textStatus =
    status.charAt(0).toUpperCase() +
    status
      .replace(/_|-|\./g, ' ')
      .slice(1)
      .toLowerCase();
  let type = 'default';

  if (greenStatus.indexOf(status) >= 0) {
    type = '#008000';
  }

  if (yellowStatus.indexOf(status) >= 0) {
    type = '#FFFF00';
  }

  if (redStatus.indexOf(status) >= 0) {
    type = '#FF0000';
  }

  if (burgundyStatus.indexOf(status) >= 0) {
    type = '#830000';
  }

  return <Badge className="formatedStatus" color={type} text={textStatus} />;
};

FormatUserLevelRender.propTypes = {
  status: PropTypes.string.isRequired,
  greenStatus: PropTypes.array,
  yellowStatus: PropTypes.array,
  redStatus: PropTypes.array,
  burgundyStatus: PropTypes.array,
};

FormatUserLevelRender.defaultProps = {
  greenStatus: ['Full'],
  yellowStatus: ['Full-investigated'],
  redStatus: ['Limited'],
  burgundyStatus: ['Denied'],
};

export default FormatUserLevelRender;
