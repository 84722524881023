import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { animOffsets, Fade, FadeDown } from '@atoms/transitions';
import { CreateTable, handleTableChange } from '@atoms/table';
import SectionHeader from '@molecules/section-header';
import Spinner from '@atoms/spinner';
import notification from '@helps/notification';

import api from 'API';
import { tConsume } from 'TRANSLATION';
import { fieldChange, responseError } from 'FIELDS';
import { CheckboxSet } from 'INPUTS';

class Overview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      isLoaded: false,
      data: [],
      subscriptions: [],
      pagination: {},
    };

    this.getData = this.getData.bind(this);

    this.handleFieldChange = this.handleFieldChange.bind(this, this);
    this.handleTableChange = handleTableChange.bind(this, this);
    this.responseError = responseError.bind(this, this);
  }

  componentDidMount() {
    this._mounted = true;
    this.getUserData();
    this.getData();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  subscribe(self, data) {
    return axios(api.notifications.aemi.subscribe(this.state[data.name].id).AXIOS('get'))
      .then((response) => {
        if (this._mounted) {
          const success = response.status !== 208;

          if (success) {
            fieldChange(self, data);
          }

          notification(response.data.message, success ? 'success' : 'error');
        }
      })
      .catch((error) => {
        if (this._mounted) {
          this.responseError(error);
        }
      });
  }

  unsubscribe(self, data) {
    return axios(api.notifications.aemi.unsubscribe(this.state[data.name].id).AXIOS('get'))
      .then((response) => {
        if (this._mounted) {
          fieldChange(self, data);
          notification(response.data.message, 'success');
        }
      })
      .catch((error) => {
        if (this._mounted) {
          this.responseError(error);
        }
      });
  }

  handleFieldChange(self, data) {
    if (this.state[data.name].value !== data.value) {
      if (data.value) {
        this.subscribe(self, data);
      } else {
        this.unsubscribe(self, data);
      }
    }
  }

  getUserData() {
    return axios(api.notifications.aemi.subscriptions.AXIOS('get'))
      .then((response) => {
        if (this._mounted) {
          const { data } = response.data;

          this.setState({
            subscriptions: data,
          });

          data.map((item) => {
            this.setState({
              [item.name]: {
                ...item,
                label: item.name.replace(/_/g, ' '),
                value: 1,
              },
            });
          });
        }
      })
      .catch((error) => {
        this.responseError(error);
      });
  }

  getData(_filters) {
    this.setState({ loading: true });
    axios(api.notifications.aemi.list.AXIOS('get', _filters))
      .then(async (response) => {
        if (this._mounted) {
          const { data, meta } = response.data;

          this.setState({
            data,
            pagination: meta
              ? {
                  pageSize: meta.per_page,
                  current: meta.current_page,
                  total: meta.total,
                }
              : false,
          });

          data.map((item) => {
            this.setState({
              [item.name]: {
                ...item,
                label: item.name,
                value: 0,
              },
            });
          });

          await this.getUserData();
        }
      })
      .catch((error) => {
        this.responseError(error);
      })
      .finally(() => {
        if (this._mounted)
          this.setState({
            loading: false,
            isLoaded: true,
          });
      });
  }

  render() {
    const { data, loading, pagination, isLoaded } = this.state;
    const { t } = this.props;
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: t('field.name.label'),
        dataIndex: 'name',
        render: (_, item) => {
          return CheckboxSet(this, [item.name], true);
        },
      },
    ];

    if (!isLoaded) {
      return <Spinner />;
    }

    const animConfig = {
      i: 2,
    };
    const offset = animOffsets(animConfig);

    return (
      <>
        <Fade delay={offset[0]}>
          <SectionHeader title={<h2>{t('page.headers.notifications')}</h2>} />
        </Fade>

        <FadeDown delay={offset[1]}>
          <CreateTable
            columns={columns}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            rowKey={'id'}
            fieldsControl={false}
            onChange={this.handleTableChange}
          />
        </FadeDown>
      </>
    );
  }
}

export default tConsume(Overview);

Overview.propTypes = {
  userType: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};
