/**
 * Get string with company_name or if it's not present,
 * string with first_name, middle_name, last_name
 * uasage:    import { NameOrCompany } from '@atoms/table';
 *            render: (company, record) => <NameOrCompany item={record} />
 * @param {string} company_name
 * or
 * @param {string} first_name
 * @param {string} middle_name
 * @param {string} last_name
 *
 */

import PropTypes from 'prop-types';

const NameOrCompany = (props) => {
  const { company_name, first_name, middle_name, last_name } = props.item;

  let text = middle_name
    ? `${first_name} ${middle_name} ${last_name}`
    : `${first_name} ${last_name}`;

  if (company_name) {
    text = company_name;
  }

  return text;
};

NameOrCompany.propTypes = {
  item: PropTypes.object.isRequired,
};

export default NameOrCompany;
