import merge from 'deepmerge';
import isObject from 'lodash.isobject';

export default function (self, unlock = false, callback) {
  const fields = {};

  Object.keys(self.state).forEach((key) => {
    let item = self.state[key];

    if (!isObject(item) || item.notField || !item.name) {
      return;
    }

    let _value = '';
    const name = item.name;
    const value = item.value;
    let field = {};

    if (typeof value === 'object') {
      _value = null;
    }

    field = { value: _value };

    if ('addonBefore' in item && item.addonBefore.name) {
      field = {
        ...field,
        ...{ addonBefore: { value: '' } },
      };
    }

    if ('addonAfter' in item && item.addonAfter.name) {
      field = {
        ...field,
        ...{ addonAfter: { value: '' } },
      };
    }

    if (unlock) {
      if (item.disabled) {
        field.disabled = false;
      }

      if (item._static) {
        field._static = false;
      }
    }

    fields[name] = field;
  });

  self.setState(
    {
      ...merge(self.state, fields),
    },
    () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    }
  );
}
