import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Row } from 'antd';

import FieldsGroup from '@atoms/form/fields-group';
import ContentWrapper from '@atoms/content-wrapper';
import RenderPreviewItem from '@molecules/formater/TextPreviewFormater';
import { tConsume } from 'TRANSLATION';

const CompanyInformation = ({ t }) => {
  const personalData = useSelector((state) => state.personal.data);
  const { company } = personalData;

  return (
    <ContentWrapper>
      <FieldsGroup header={t('fieldsGroup.companyInformation.title')}>
        <Row gutter={20} className="text-preview">
          {company &&
            company.name &&
            RenderPreviewItem(t('field.company_name.label'), `${company.name}`, 22)}

          {company &&
            company.registration_number &&
            RenderPreviewItem(
              t('field.registration_number.label'),
              `${company.registration_number}`,
              22
            )}
        </Row>
      </FieldsGroup>
    </ContentWrapper>
  );
};

export default tConsume(CompanyInformation);

CompanyInformation.propTypes = {
  t: PropTypes.func.isRequired,
};
