export default {
  default: {
    name: 'Auth',
  },
  top: [],
  bottom: [],
  additional: [
    {
      name: 'messages',
      icon: 'message',
      active: true,
      path: 'requests',
    },
  ],
};
