import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Row, Col } from 'antd';
import getFormData from 'get-form-data';

import FieldsGroup from '@atoms/form/fields-group';
import Hint from '@molecules/hint';

import { fieldChange, fieldValidation, NUMBER_RULES } from 'FIELDS';
import { InputsSet } from 'INPUTS';
import { tConsume } from 'TRANSLATION';
import { Strings } from 'HELPERS';
import defaults from 'DEFAULTS';

class TfaQrForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tfa_code: {
        name: 'tfa_code',
        type: 'number',
        accept: defaults.re.digits,
        required: true,
        placeholder: true,
        label: props.t('field.code'),
        [NUMBER_RULES.LENGTH]: 6,
        autofocus: true,
      },
    };

    this.handleFieldChange = fieldChange.bind(this, this);
    this.fieldValidation = fieldValidation.bind(this, this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { onConfirm } = this.props;
    const data = getFormData(event.target, { trim: true });

    if (!!data && this.fieldValidation()) {
      onConfirm({ tfa_code: data.tfa_code });
    }
  }

  render() {
    const { type, qrSrc, loading, t } = this.props;
    const { tfa_code } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        {type === 'bind' && (
          <div className={'qrCode'}>{qrSrc && <img src={qrSrc} alt={'qrcode'} />}</div>
        )}

        <FieldsGroup
          title={
            <Strings.CompileComponent
              str={`${t('component.tfa.google_description')} ${type === 'check' ? '' : '{0}'}`}
              replace={[<Hint key={0} content={'TFAGoogle'} type={'popup'} />]}
            />
          }
        />

        <Row gutter={20}>
          <Col sm={16}>{InputsSet(this, ['tfa_code'])}</Col>
          <Col sm={8}>
            <Button
              size={defaults.antd.button.size}
              loading={loading}
              htmlType={'submit'}
              type={'primary'}
              disabled={tfa_code.value?.length !== 6}
              block
            >
              {t('button.confirm')}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default tConsume(TfaQrForm);

TfaQrForm.propTypes = {
  type: PropTypes.oneOf(['bind', 'get', 'check']),
  loading: PropTypes.bool,
  qrSrc: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

TfaQrForm.defaultProps = {
  loading: false,
};
