/**
 *
 * @param {string} userType
 * @returns {bool}
 *
 */
import { store } from 'STORE';

export default (userType = false) => {
  const userToken = store.getState().userToken;
  const token = userToken.token;
  const tokenType = userToken.tokenType === userType;
  return !!token && !!tokenType;
};
