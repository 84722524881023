import React from 'react';
import PropTypes from 'prop-types';

import Logo from '@atoms/logo';
import ConditionsBlock from '@organisms/auth/ConditionsBlock';

const RegistrationBlockWrapper = ({
  userType,
  containerClass,
  mainClass,
  adaptiveLogo,
  children,
}) => {
  return (
    <div className={`registrationBlock ${containerClass || ''}`}>
      <div className={'positionWrapper'}>
        <div className={'header'}>
          <Logo route={`/${userType}`} adaptive={adaptiveLogo} />
        </div>

        <div className={`main ${mainClass || ''}`}>{children}</div>

        <div />
      </div>

      <div className={'footer'}>
        <ConditionsBlock />
      </div>
    </div>
  );
};

export default RegistrationBlockWrapper;

RegistrationBlockWrapper.propTypes = {
  userType: PropTypes.string.isRequired,
  containerClass: PropTypes.string,
  mainClass: PropTypes.string,
  adaptiveLogo: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]).isRequired,
};

RegistrationBlockWrapper.defaultProps = {
  adaptiveLogo: true,
};
