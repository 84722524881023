import React from 'react';
import { Route } from 'react-router-dom';
import { T } from './context';

class PageRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      props: props,
    };
  }

  static contextType = T;
  componentDidMount() {
    if (this.context && this.context.setPage) {
      this.context.setPage(this.props.page);
    }
  }

  render() {
    return (
      <Route
        {...this.state.props}
        render={(props) => {
          if (this.props.page !== null) {
            const _context_page = Array.isArray(this.context.page)
              ? this.context.page.join(',')
              : this.context.page;
            const _props_page = Array.isArray(this.props.page)
              ? this.props.page.join(',')
              : this.props.page;
            if (_context_page !== _props_page) {
              return null;
            }
          }

          return this.props.render({ t: this.context.translation, __tMounted: true, ...props });
        }}
      />
    );
  }
}

export default PageRoute;
