import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
import { Button, Modal } from 'antd';

import Spinner from '@atoms/spinner';
import { tConsume } from 'TRANSLATION';

const AsyncTfaQr = Loadable({
  loader: () => import('./TfaQr'),
  loading: Spinner,
});

const AsyncTfaPhone = Loadable({
  loader: () => import('./TfaPhone'),
  loading: Spinner,
});

const FORM = {
  PHONE: 'phone',
  GOOGLE: 'google',
};

class TfaEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  async toggleModal(visible = !this.state.visible) {
    this.setState({ visible: visible });
  }

  render() {
    const { disabled, methodShortName, t } = this.props;
    const { visible } = this.state;

    return (
      <>
        <Button
          type={'primary'}
          icon={'plus'}
          disabled={disabled}
          onClick={() => this.toggleModal(true)}
        />

        <Modal
          visible={visible}
          destroyOnClose={true}
          maskClosable={false}
          title={t(`component.tfa.tfa_${methodShortName}_title`)}
          footer={null}
          onCancel={() => this.toggleModal(false)}
        >
          {methodShortName === FORM.GOOGLE && (
            <AsyncTfaQr type={'bind'} successCallback={() => this.toggleModal(false)} />
          )}

          {methodShortName === FORM.PHONE && (
            <AsyncTfaPhone type={'bind'} successCallback={() => this.toggleModal(false)} />
          )}
        </Modal>
      </>
    );
  }
}

export default tConsume(TfaEditModal);

TfaEditModal.propTypes = {
  methodShortName: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

TfaEditModal.defaultProps = {
  disabled: true,
};
