import defaults from 'DEFAULTS';

export default (value) => {
  const country = value
    ? defaults.location.country.filter((item) => item.phone_code === value)[0]
    : null;

  if (!country) {
    return '';
  }

  return `${country.country_code}#${value}`;
};
