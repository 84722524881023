import React from 'react';

export default function Template() {
  return (
    <div>
      <h3>Professional status certification</h3>
      <p>
        A “Professional Investor” is an investor who is considered to be a professional client or
        who may, on request, be treated as a professional client within the relevant national
        implementation of Annex II of Directive 2014/65/EU (“Markets in Financial Instruments
        Directive”), as amended or supplemented from time to time and Directive 2011/61/EU
        (“Alternative Investment Fund Managers Directive”), as amended or supplemented from time to
        time.
      </p>
    </div>
  );
}
