import React from 'react';
import FormSwitch from '@atoms/form/form-item/FormSwitch';
import setDisabled from '@atoms/form/funcs/setDisabled';

export default (self = {}, array = []) => {
  const state = self.state;

  if (!state) {
    return null;
  }

  return (
    <>
      {array.map((element) => {
        const object = state[element];
        const { disabled, _static } = setDisabled(object, self);

        return (
          <FormSwitch
            key={element}
            item={object}
            onChange={self.handleFieldChange}
            loading={self.state.loading}
            disabled={disabled}
            _static={_static}
          />
        );
      })}
    </>
  );
};
