import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { useDispatch } from 'react-redux';

import PhoneChange1step from './PhoneChange1step';
import { changePersonalDataField } from '@features/personalSlice';

import { tConsume } from 'TRANSLATION';

const PhoneChange = ({ userType, t }) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const toggleModal = (show = !visible, result) => {
    setVisible(show);

    if (!show && result) {
      dispatch(changePersonalDataField(result.data));
    }
  };

  return (
    <>
      <Button type={'dashed'} shape={'circle'} icon={'edit'} onClick={() => toggleModal(true)} />

      <Modal
        visible={visible}
        destroyOnClose={true}
        maskClosable={false}
        title={t('component.phoneNumber.change')}
        footer={null}
        onCancel={() => toggleModal(false)}
      >
        <PhoneChange1step
          userType={userType}
          toggleModal={(visible, result) => toggleModal(visible, result)}
        />
      </Modal>
    </>
  );
};

export default tConsume(PhoneChange);

PhoneChange.propTypes = {
  userType: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};
