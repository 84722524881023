export function getMinAmountForCurrency(value, list) {
  let minLimit = 0;
  const foundCurr = list.find((curr) => curr.value === value);

  if (foundCurr && foundCurr.hasOwnProperty('min_limit')) {
    minLimit = parseFloat(foundCurr.min_limit);
  }

  return minLimit;
}
