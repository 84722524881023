export default {
  parts: [
    'api',
    'button',
    'component',
    'errors',
    'fieldsGroup',
    'field',
    'keys',
    'notification',
    'pageHeader',
    'registrationDeclined',
    'menu',
    'tooltip',
    'countries',
  ],
  page_base: 'page/',
  template_base: 'template/',
};
