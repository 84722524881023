// Usage render: (amount, record) => <FormatedMoney item={record} />

import React from 'react';
import defaults from 'DEFAULTS';
import PropTypes from 'prop-types';

const FormatMoneyRender = ({ item, list }) => {
  const { amount, currency, direction, type } = item;
  let inOut;
  let inOutClass = 'formatedMoney_incoming'; // src/styles/partials/_formatedMoney.less
  const _direction = direction || type;

  if (['OUTBOUND', 'PAYOUT', 'FEE'].indexOf(_direction.toUpperCase()) >= 0) {
    inOut = '-';
    inOutClass = 'formatedMoney_outcoming';
  }

  return (
    <span className={inOutClass}>
      {inOut}
      {defaults.currency.format(amount, currency, list)}
    </span>
  );
};

FormatMoneyRender.propTypes = {
  item: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
};

export default FormatMoneyRender;
