import defaults from 'DEFAULTS';
import countryCodeEmoji from 'country-code-emoji';

const countryListSelects = defaults.location.country.map((item) => {
  const { title, country_code } = item;
  return {
    ...item,
    title: `${countryCodeEmoji(country_code)} ${title}`,
    value: country_code,
  };
});

const countryListFilters = countryListSelects.map((item) => {
  const { title } = item;
  return {
    ...item,
    text: title,
  };
});

const selectCountries = (filter) => {
  if (Array.isArray(filter)) {
    return countryListSelects.filter((item) => filter.indexOf(item.value) >= 0);
  }

  return countryListSelects;
};

export { selectCountries as default, countryListFilters };
