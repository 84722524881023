import moment from 'moment-timezone';

const dateObject = {
  serverDate: 'YYYY-MM-DD',
  serverTime: 'HH:mm:ss',
  serverDateTime: 'YYYY-MM-DD HH:mm:ss',
  localDate: 'L',
  localTime: 'LT',
  localDateTime: 'L LT',
  localDateTime24: 'DD/MM/YYYY HH:mm:ss',
  defaultDate: 'DD/MM/YYYY',
  timeZone: 'UTC',
};

const dateLocal = (
  date = null,
  format = dateObject.localDateTime,
  serverTimezone = dateObject.timeZone
) => {
  if (!date) {
    return '';
  }

  // const localTimezone = moment.tz.guess();
  const serverDateTime = moment.tz(date, dateObject.serverDateTime, serverTimezone);

  // return serverDateTime.tz(localTimezone).format(format);
  return serverDateTime.format(format);
};

export default {
  ...dateObject,
  local: dateLocal,
};
