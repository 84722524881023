export function getCodeRegularForCurrency(value, list) {
  let code = '';
  const foundCurr = list.find((curr) => curr.value === value);

  if (foundCurr && foundCurr.hasOwnProperty('code_regex')) {
    code = foundCurr.code_regex;
  }

  return code;
}
