import React from 'react';

export default function Template() {
  return (
    <div>
      <h3>
        A passphrase is a sequence of words or other text used to control access to information,
        provided by the financial institution.{' '}
      </h3>
    </div>
  );
}
