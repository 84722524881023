import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'antd';

export default function ExpandIcon(props) {
  const { expanded, onExpand, record } = props;

  let classActive = '';

  if (expanded) {
    classActive = 'active';
  }

  return <Icon type="down" className={classActive} onClick={(event) => onExpand(record, event)} />;
}

ExpandIcon.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onExpand: PropTypes.func,
  record: PropTypes.object,
};
