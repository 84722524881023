export default function cascade({ per_row, count, start, delay }) {
  const result = [];
  for (let i = 1; i <= count; i++) {
    let row = Math.ceil(i / per_row);
    let col = i % per_row || per_row;
    result.push({ offset: +start + (row + col - 1) * delay, key: `key${i}` });
  }

  return result;
}
