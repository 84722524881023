// usage render: date => <FormatedDate status={status} greenStatus={'APPROVED'}... />

import React from 'react';
import PropTypes from 'prop-types';

import { Badge } from 'antd';

const FormatStatusRender = ({ status, greenStatus, yellowStatus, blueStatus, redStatus }) => {
  let textStatus =
    status.charAt(0).toUpperCase() +
    status
      .replace(/_|-|\./g, ' ')
      .slice(1)
      .toLowerCase();
  let type = 'default';

  if (greenStatus.indexOf(status) >= 0) {
    type = 'success';
  }

  if (yellowStatus.indexOf(status) >= 0) {
    type = 'warning';
  }

  if (blueStatus.indexOf(status) >= 0) {
    type = 'processing';
  }

  if (redStatus.indexOf(status) >= 0) {
    type = 'error';
  }

  return <Badge className="formatedStatus" status={type} text={textStatus} />;
};

FormatStatusRender.propTypes = {
  status: PropTypes.string.isRequired,
  greenStatus: PropTypes.array,
  yellowStatus: PropTypes.array,
  blueStatus: PropTypes.array,
  redStatus: PropTypes.array,
};

FormatStatusRender.defaultProps = {
  greenStatus: ['PROCESSED'],
  yellowStatus: ['PENDING', 'CANCELED'],
  blueStatus: ['PROCESSING', 'COMPLETED'],
  redStatus: ['FAILED', 'DECLINED'],
};

export default FormatStatusRender;
