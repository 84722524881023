import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import Spinner from '@atoms/spinner';
import PageNotFound from '@templates/page-not-found';
import Authorization from '@templates/authorization/Authorization';

import PrivateRoute from './privateRoute';
import DashboardPage from './dashboard';
import PagePasswordReset from './auth/password-reset';
import Logout from './auth/logout';

import { PageRoute } from 'TRANSLATION';
import defaults from 'DEFAULTS';

const AsyncRegistration = Loadable({
  loader: () => import('./auth/registration'),
  loading: Spinner,
  delay: 60,
});

export default class RouteProcessor extends Component {
  render() {
    const { AUTHORIZED, VERIFIED, match } = this.props;
    const userType = match.params.userType;
    const accountTypes = defaults.variables.account_types;
    const allowedUserTypes = [...accountTypes, 'aemi'];
    const routeLogin = `/${userType}`;
    const routeDashboard =
      allowedUserTypes.indexOf(userType) >= 0 ? `/${userType}/dashboard` : false;
    const routePasswordReset = `/${userType}/password-reset`;
    const routeRegistration = `/${userType}/registration`;
    const routeLogout = `/${userType}/logout`;

    if (!routeDashboard) {
      return (
        <Switch>
          <Route render={() => <PageNotFound />} />
        </Switch>
      );
    }

    return (
      <Switch>
        <PageRoute
          page={'authorization'}
          exact
          path={routeLogin}
          render={(t) => (
            <Authorization
              userType={userType}
              route={routeLogin}
              redirectRoute={routeDashboard}
              {...t}
            />
          )}
        />

        <Route
          page={'authorization'}
          path={routeRegistration}
          render={() => (
            <AsyncRegistration
              userType={userType}
              route={routeRegistration}
              dashboardRoute={routeDashboard}
            />
          )}
        />

        <Route
          page={'authorization'}
          path={routePasswordReset}
          render={() => (
            <PagePasswordReset
              userType={userType}
              route={routePasswordReset}
              redirectRoute={routeLogin}
            />
          )}
        />

        <Route
          path={routeLogout}
          render={() => <Logout userType={userType} route={routeLogout} />}
        />

        <PrivateRoute
          path={routeDashboard}
          authorized={AUTHORIZED}
          userType={userType}
          render={() => (
            <DashboardPage userType={userType} verified={VERIFIED} route={routeDashboard} />
          )}
        />

        <Route render={() => <PageNotFound />} />
      </Switch>
    );
  }
}

RouteProcessor.propTypes = {
  AUTHORIZED: PropTypes.bool.isRequired,
  VERIFIED: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
};
