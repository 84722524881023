import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Button } from 'antd';
import Logo from '@atoms/logo';

import defaults from 'DEFAULTS';
import { tConsume } from 'TRANSLATION';

class ErrorPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
    };
  }

  render() {
    const { redirect } = this.state;
    const { t, code, message, logo, path } = this.props;

    if (redirect) {
      if (window.history) {
        window.history.back();

        return <></>;
      }

      return <Redirect to={path} />;
    }

    return (
      <div className="errorPageWrapper">
        <div className={'align-center'}>{logo && <Logo />}</div>

        <div className="centerBlock">
          <h1 className="code align-center">{code}</h1>
          <div className="oops align-center">{t('component.oops404')}</div>
          <div className="message align-center">
            {message ? message : t('component.message404')}
          </div>
        </div>

        <div className="button">
          <Button
            type="primary"
            size={defaults.antd.button.size}
            onClick={() => {
              this.setState({ redirect: true });
            }}
          >
            {t('button.back')}
          </Button>
        </div>
      </div>
    );
  }
}

export default tConsume(ErrorPage);

ErrorPage.propTypes = {
  t: PropTypes.func.isRequired,
  code: PropTypes.number,
  message: PropTypes.string,
  logo: PropTypes.bool,
  path: PropTypes.string,
};

ErrorPage.defaultProps = {
  code: 404,
  message: '',
  logo: true,
  path: '/',
};
