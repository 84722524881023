import isEmail from 'validator/lib/isEmail';
import msg from '../getErrorMsg';

export default (input, params, context) => {
  if (!isEmail(input.value)) {
    return msg(context, 'notEmail');
  }

  return false;
};
