import React from 'react';

export default function Template() {
  return (
    <div>
      <h3>Financial Institution status:</h3>
      <p>
        Financial Institution is a company that is authorized and supervised by a local financial
        regulator and is engaged in the business of dealing with financial transactions such as
        deposits, loans, investments, electronic payments, insurance and currency exchange.
      </p>
      <p>Examples of Financial Institutions:</p>
      <ol>
        <li>Credit Institution;</li>
        <li>Electronic Money Institution;</li>
        <li>Investment Firm;</li>
        <li>Collective investment scheme (Fund).</li>
      </ol>
    </div>
  );
}
