import React from 'react';

import defaults from 'DEFAULTS';

export default function Template() {
  const renderSiteName = () => {
    const { home, title } = defaults.website;

    if (home.protocol && home.host) {
      return (
        <a href={`${home.protocol}://${home.host}`} target={'_blank'}>
          {home.host}
        </a>
      );
    }

    return <strong>{title}</strong>;
  };

  return (
    <div>
      <h3>To enable 2FA for {renderSiteName()}</h3>
      <ol>
        <li>Set up the Authenticator app on your smart device </li>
        <li>Open the app</li>
        <li>Scan QR code</li>
        <li>Enter the code from the app into the field below the QR code</li>
        <li>Select “Confirm“ to confirm the changes.</li>
      </ol>
    </div>
  );
}
