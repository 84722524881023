import isURL from 'validator/lib/isURL';
import msg from '../getErrorMsg';

export default (input, params, context) => {
  if (!isURL(input.value)) {
    return msg(context, 'notUrl');
  }

  return false;
};
