// import phone from 'phone';
// import msg from '../getErrorMsg';

// export default (input, params, context) => {
//   const { value, addonBefore } = input;

//   let phoneNumber = value || '';

//   if (addonBefore && addonBefore.value) {
//     phoneNumber = `+${addonBefore.value.split('#')[1].replace(/ /g, '')}${phoneNumber}`;
//   }

//   if (!phone(phoneNumber).length) {
//     return msg(context, 'notPhone');
//   }

//   return false;
// };

export default () => {
  return false;
};
