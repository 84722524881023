import React from 'react';
import { Form } from 'antd';
import InputRow from '@atoms/form/input-row';
import FormCheckbox from '@atoms/form/form-item/FormCheckbox';
import setLabel from '@atoms/form/funcs/setLabel';
import setDisabled from '@atoms/form/funcs/setDisabled';

export default (self = {}, array = [], row = false, validation = false, style = {}) => {
  const state = self.state;

  if (!state) {
    return null;
  }

  return (
    <>
      {array.map((element) => {
        const object = state[element];

        if (object) {
          const label = setLabel(object, self.props && self.props.t);

          const { disabled, _static } = setDisabled(object, self);

          if (row) {
            return (
              <InputRow
                key={element}
                label={label}
                disabled={disabled || _static}
                style={style}
                labelAction={self.handleFieldChange}
                item={object}
              >
                <FormCheckbox
                  item={object}
                  withLabel={false}
                  onChange={self.handleFieldChange}
                  loading={self.state.loading}
                  disabled={disabled}
                  _static={_static}
                />
              </InputRow>
            );
          }

          return (
            <FormCheckbox
              key={element}
              item={object}
              withLabel={object.label === false ? false : true}
              onChange={self.handleFieldChange}
              loading={self.state.loading}
              disabled={disabled}
            />
          );
        }
        return <React.Fragment key={element} />;
      })}

      {!('_static' in self.state && self.state._static) && validation && row && (
        <Form.Item
          style={{ marginBottom: 0 }}
          help={state[validation].help}
          validateStatus={state[validation].status}
        />
      )}
    </>
  );
};
