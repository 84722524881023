import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';

const UserAvatar = ({ name = '', src = '' }) => {
  const fullName = name.split(' ');
  const firstName = fullName[0];
  const lastName = fullName[1];

  return (
    <Avatar
      size="large"
      src={src}
      icon={!firstName && !lastName && !src ? 'user' : ''}
      style={!firstName && !lastName && !src ? { backgroundColor: '#87d068' } : {}}
    >
      {!src && firstName && firstName.charAt(0).toUpperCase()}
      {!src && lastName && lastName.charAt(0).toUpperCase()}
    </Avatar>
  );
};

export default UserAvatar;

UserAvatar.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,
};
