import React from 'react';

export default function Template() {
  return (
    <div>
      <h3>To proof address one of the following documents must be uploaded: </h3>
      <ul>
        <li>
          Gas, electricity, water or landline bill, bank statement or an additional
          government-issued ID, containing an address on it, dated within the last three months
        </li>
      </ul>
    </div>
  );
}
