import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Menu, Drawer } from 'antd';

// import defaults from 'DEFAULTS';
// import LanguageChange from '@molecules/button/LanguageChange';
// import SupportMail from '@molecules/button/SupportMail';

const MobileMenu = ({ visible, defaultOpenKeys, defaultSelectedKeys, menuItems, onClose }) => {
  const [activePanel, setActivePanel] = useState('');
  const [miniPanel, setMiniPanel] = useState([]);
  const [panel, setPanel] = useState([]);

  useEffect(() => {
    // const props = {
    //   type: 'link',
    //   size: defaults.antd.button.size,
    //   tooltip: false,
    //   handleClick: this.setPanelContent,
    // };

    const panel = [
      // { key: 'supportMail', content: <SupportMail {...props} /> },
      // {
      //   key: 'languageChange',
      //   content: <LanguageChange {...props} label={false} key={'LanguageChange'} />,
      // },
    ];

    const miniPanel = panel.map(({ key }) => ({ key, content: null }));

    setMiniPanel(miniPanel);
    setPanel(panel);
  }, []);

  const renderMiniPanel = () => {
    return (
      <div className="menu-mini-panel">
        <Row className="wrapper" type="flex" gutter={5}>
          {panel.map(({ key, content }) => (
            <Col key={key}>{content}</Col>
          ))}
        </Row>

        <Menu className="menu-mini-panel-content" openKeys={[activePanel]} mode="inline">
          {miniPanel.map(({ key, content }) => (
            <Menu.SubMenu key={key}>{content}</Menu.SubMenu>
          ))}
        </Menu>
      </div>
    );
  };

  // eslint-disable-next-line no-unused-vars
  const setPanelContent = (data) => {
    let row = null;

    miniPanel.forEach(({ key, content }, i) => {
      if (key === data.key && content === null) {
        row = i;
      }
    });

    const _miniPanel = {};

    if (row !== null) {
      _miniPanel.miniPanel = miniPanel.slice();
      _miniPanel.miniPanel[row] = data;
    }

    setMiniPanel(_miniPanel.miniPanel);
    setActivePanel(activePanel !== data.key ? data.key : '');
  };

  return (
    <Drawer
      placement="bottom"
      closable={false}
      visible={visible}
      destroyOnClose={true}
      height="60vh"
      onClose={onClose}
      className="dashboard-wrapper-menu-mobile"
    >
      {renderMiniPanel()}

      <Menu
        mode="inline"
        style={{ borderRight: 'none' }}
        defaultOpenKeys={defaultOpenKeys}
        defaultSelectedKeys={defaultSelectedKeys}
      >
        {menuItems}
      </Menu>
    </Drawer>
  );
};

export default MobileMenu;

MobileMenu.propTypes = {
  visible: PropTypes.bool.isRequired,
  defaultOpenKeys: PropTypes.array.isRequired,
  defaultSelectedKeys: PropTypes.array.isRequired,
  menuItems: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};
