import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import isObject from 'lodash.isobject';
import { notHTML } from '@common/helps/isHtml';

export default class FieldsGroup extends Component {
  render() {
    const { header, subheader, title, description, loading, rows } = this.props;
    const text = {
      header,
      subheader,
      title,
      description,
    };

    Object.keys(text).forEach((key) => {
      const item = text[key];

      if (item) {
        if (isObject(item)) {
          if (key in item) {
            text[key] = item[key];
            return;
          }

          if (notHTML(item)) {
            text[key] = '';
            return;
          }
        }
      }
    });

    return (
      <div className="fieldSet">
        {!!text.header && (
          <div className="fieldSetHeader">
            <h2>
              {text.header}
              {text.subheader && <span>{text.subheader}</span>}
            </h2>
          </div>
        )}

        {!!text.title && (
          <div className="fieldSetTitle">
            <h3>
              {text.title}
              {!!text.description && <span>{text.description}</span>}
            </h3>
          </div>
        )}
        <Skeleton title={false} loading={loading} paragraph={{ rows: rows }} active>
          {this.props.children}
        </Skeleton>
      </div>
    );
  }
}

FieldsGroup.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  loading: PropTypes.bool,
  rows: PropTypes.number,
};

FieldsGroup.defaultProps = {
  loading: false,
  rows: 5,
};
