import React from 'react';

export default function Template() {
  return (
    <div>
      <h3>
        “Politically exposed person” or “PEP” means an individual who is entrusted with prominent
        public functions, other than as a middle-ranking or more junior official:
      </h3>
      <ol>
        {/* 1 */}
        <li>
          “family member” of a politically exposed person includes:
          <ol type="a">
            <li>a spouse or civil partner of the PEP;</li>
            <li>children of the PEP and the spouses or civil partners of the PEP’s children;</li>
            <li>parents of the PEP;</li>
          </ol>
        </li>
        {/* 2 */}
        <li>
          “known close associate” of a PEP means:
          <ol type="a">
            <li>
              an individual known to have joint beneficial ownership of a legal entity or a legal
              arrangement or any other close business relations with a PEP;
            </li>
            <li>
              an individual who has sole beneficial ownership of a legal entity or a legal
              arrangement which is known to have been set up for the benefit of a PEP.
            </li>
          </ol>
        </li>
        {/* 3 */}
        <li>
          individuals entrusted with prominent public functions include:
          <ol type="a">
            <li>
              heads of state, heads of government, ministers and deputy or assistant ministers;
            </li>
            <li>members of parliament or of similar legislative bodies;</li>
            <li>members of the governing bodies of political parties;</li>
            <li>
              members of supreme courts, of constitutional courts or of any judicial body the
              decisions of which are not subject to further appeal except in exceptional
              circumstances;
            </li>
            <li>members of courts of auditors or of the boards of central banks;</li>
            <li>ambassadors, charges d’affaires and high-ranking officers in the armed forces;</li>
            <li>
              members of the administrative, management or supervisory bodies of State-owned
              enterprises;
            </li>
            <li>
              directors, deputy directors and members of the board or equivalent function of an
              international organisation.
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
}
