import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

export default class UITooltip extends Component {
  render() {
    const { placement, title, hide, className, ...spread } = this.props;

    return (
      <Tooltip
        placement={placement}
        title={title}
        {...(className ? { className } : {})}
        {...(hide ? { visible: false } : {})}
      >
        <span {...spread}>{this.props.children}</span>
      </Tooltip>
    );
  }
}

UITooltip.propTypes = {
  className: PropTypes.string,
  placement: PropTypes.string,
  title: PropTypes.string.isRequired,
  hide: PropTypes.bool,
};

UITooltip.defaultProps = {
  placement: 'bottom',
};
