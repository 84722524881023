import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'antd';
import getFormData from 'get-form-data';
import axios from 'axios';

import FieldsGroup from '@atoms/form/fields-group';
import ContentWrapper from '@atoms/content-wrapper';
import PasswordCriteriaList from '@atoms/PasswordCriteriaList/PasswordCriteriaList';
import ConfirmationWrapper from '@molecules/security/ConfirmationWrapper';
import notification from '@helps/notification';

import api from 'API';
import { fieldValidation, fieldBlur, responseError, clearFields } from 'FIELDS';
import { PasswordSet } from 'INPUTS';
import { tConsume } from 'TRANSLATION';
import defaults from 'DEFAULTS';

class PasswordChange extends Component {
  constructor(props) {
    super(props);

    this.state = {
      waitingForCode: '',
      form: 'passwordChange',
      loading: false,
      data: {},
      validation: [],
      old_password: {
        name: 'old_password',
        type: 'password',
        autoComplete: 'new-password',
        required: true,
        validation: { isPassword: true },
        placeholder: true,
      },
      new_password: {
        name: 'new_password',
        type: 'password',
        autoComplete: 'new-password',
        required: true,
        validation: { isPassword: true },
        placeholder: true,
      },
      new_password_confirmation: {
        name: 'new_password_confirmation',
        type: 'password',
        autoComplete: 'new-password',
        required: true,
        placeholder: true,
      },
    };

    this.handleFieldBlur = fieldBlur.bind(this, this);
    this.fieldValidation = fieldValidation.bind(this, this);
    this.responseError = responseError.bind(this, this);
    this.clearFields = clearFields.bind(this, this);

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkboxFilling = this.checkboxFilling.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  resetForm() {
    this.setState(
      {
        data: {},
        validation: [],
        loading: false,
        waitingForCode: '',
      },
      () => this.clearFields(true)
    );
  }

  handleFieldChange(item) {
    this.setState(
      (state) => {
        state[item.name] = {
          ...state[item.name],
          ...item,
        };
        return state;
      },
      () => this.checkboxFilling()
    );
  }

  handleSubmit(event) {
    event.preventDefault();

    const { userType } = this.props;
    const data = getFormData(event.target, { trim: true });

    // If all fields are correct
    // If the access code field on the page
    if (this.fieldValidation() && data.password === data.password_confirmation) {
      this.setState({
        loading: true,
        data,
      });

      axios(api.users.profile.changePassword(userType).AXIOS('post', data))
        .then((response) => {
          if (this._mounted) {
            const tfa = response.data.message.auth_type;

            this.setState({
              redirect: true,
              waitingForCode: tfa,
            });
            if (!tfa) {
              this.resetForm();
              notification(response.data.message, 'success');
            }
          }
        })
        .catch((error) => {
          if (this._mounted) {
            this.responseError(error);
          }
        })
        .finally(() => {
          if (this._mounted) this.setState({ loading: false });
        });
    }
  }

  checkboxFilling() {
    const checkboxes = [];
    const lowerCase = new RegExp('[a-z]+');
    const upperCase = new RegExp('[A-Z]+');
    const number = new RegExp('[0-9]+');
    const longer = new RegExp('[^]{8,}?');

    const field1 = this.state.new_password.value;
    const field2 = this.state.new_password_confirmation.value;

    if (field1 && number.test(field1)) {
      checkboxes.push('specialChar');
    }

    if (field1 && lowerCase.test(field1)) {
      checkboxes.push('smallChar');
    }

    if (field1 && upperCase.test(field1)) {
      checkboxes.push('upperChar');
    }

    if (field1 && longer.test(field1)) {
      checkboxes.push('longer');
    }

    if (field1 && field2 && field1 === field2) {
      checkboxes.push('match');
    }

    this.setState({ validation: checkboxes });
  }

  render() {
    const { form, loading, validation, waitingForCode, data } = this.state;
    const { t, userType, personalData } = this.props;
    const email = personalData && personalData.email ? personalData.email : '';

    if (waitingForCode) {
      return (
        <ContentWrapper>
          <ConfirmationWrapper
            userType={userType}
            data={data}
            link={api.users.profile.changePassword(userType)}
            toggleModal={this.resetForm}
            tfaMethod={waitingForCode}
            textSuccess={
              <h2 className={'align-center'}>{t('component.passwordChange.password_changed')}</h2>
            }
          />
        </ContentWrapper>
      );
    }

    return (
      <ContentWrapper>
        <Form
          id={form}
          onSubmit={this.handleSubmit}
          className={'authorization-page section form-large payment-form'}
        >
          <FieldsGroup header={t('fieldsGroup.passwordChange')}>
            <input name={'email'} value={email} autoComplete={'username'} type={'hidden'} />

            {PasswordSet(this, ['old_password', 'new_password', 'new_password_confirmation'])}

            <PasswordCriteriaList
              validation={validation}
              nameOfClass={'right-block-common right-block-mobile'}
              nameOfInternalClass={'criteriaList_profile'}
              cyborg={false}
            />

            <Button
              type={'primary'}
              htmlType={'submit'}
              size={defaults.antd.button.size}
              loading={loading}
              block
              disabled={validation.length !== 5}
            >
              {t('button.continue')}
            </Button>
          </FieldsGroup>
        </Form>
      </ContentWrapper>
    );
  }
}

export default tConsume(PasswordChange);

PasswordChange.propTypes = {
  userType: PropTypes.string.isRequired,
  personalData: PropTypes.object,
  t: PropTypes.func.isRequired,
};
