import React from 'react';
import { Row, Col } from 'antd';
import { InputsSet } from 'INPUTS';

export default (self, fields) => {
  const state = self.state;
  let _fields = [];

  if (fields && Array.isArray) {
    _fields = fields;
  }

  if (_fields.length == 0 && fields) {
    _fields = [fields.name, fields.last_name, fields.middle_name];
  }

  if (!state || _fields.length == 0) {
    return null;
  }

  if (!_fields[2]) {
    return (
      <>
        {InputsSet(self, [_fields[0]])}
        {InputsSet(self, [_fields[1]])}
      </>
    );
  }

  if ('addonBefore' in state[_fields[0]] || 'addonAfter' in state[_fields[0]]) {
    return (
      <>
        {InputsSet(self, [_fields[0]])}
        <Row gutter={10}>
          <Col md={12}>{InputsSet(self, [_fields[2]])}</Col>
          <Col md={12}>{InputsSet(self, [_fields[1]])}</Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <Row gutter={10}>
        <Col md={12}>{InputsSet(self, [_fields[0]])}</Col>
        <Col md={12}>{InputsSet(self, [_fields[2]])}</Col>
      </Row>
      {InputsSet(self, [_fields[1]])}
    </>
  );
};
