import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'antd';
import { Link } from 'react-router-dom';
import getFormData from 'get-form-data';
import axios from 'axios';

import notification from '@helps/notification';
import Wrapper from '@molecules/wrappers/RobotWrapper';
import RegistrationBlockWrapper from '@molecules/wrappers/RegistrationBlockWrapper';
import LogInLink from '@organisms/auth/LogInLink';

import { InputsSet } from 'INPUTS';
import { fieldValidation, fieldBlur, fieldChange, responseError } from 'FIELDS';
import api from 'API';
import { tConsume } from 'TRANSLATION';
import { Clapping } from 'IMAGE';
import defaults from 'DEFAULTS';

class PasswordRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: 'passwordRequest',
      loading: false,
      formSubmited: false,
      email: {
        name: 'email',
        type: 'email',
        required: true,
        validation: { isEmail: true },
        autoComplete: 'email',
        placeholder: true,
      },
    };

    this._mounted = false;

    this.handleFieldChange = fieldChange.bind(this, this);
    this.handleFieldBlur = fieldBlur.bind(this, this);
    this.fieldValidation = fieldValidation.bind(this, this);

    this.responseError = responseError.bind(this, this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  /**
   *
   * Required by child component
   * @param {*} item
   *
   */
  handleFieldChange(item) {
    this.setState({ [item.name]: item });
  }

  /**
   *
   * @param {*} state
   * @returns {bool} of result
   *
   */
  fieldValidation(state = this.state) {
    let errors = false;

    Object.keys(state).map((key) => {
      let item = this.state[key];

      if (typeof item === 'object' && typeof item.validate === 'function' && !item.validate(item)) {
        errors = true;
      }
    });

    return !errors;
  }

  /**
   *
   * Submit event
   * @param {*} event
   *
   */
  handleSubmit(event) {
    event.preventDefault();

    const { t, userType } = this.props;

    const data = getFormData(event.target, { trim: true });

    // If all fields are correct
    // If the access code field on the page
    if (Object.keys(data).length && this.fieldValidation(data)) {
      this.setState({
        loading: true,
      });

      axios(api.users.password(userType, 'request-reset').AXIOS('post', data))
        .then(() => {
          if (this._mounted) {
            notification(t('notification.emailSuccess'), 'success');

            this.setState({
              formSubmited: true,
            });
          }
        })
        .catch((error) => {
          if (this._mounted) {
            this.responseError(error);
          }
        })
        .finally(() => {
          if (this._mounted) this.setState({ loading: false });
        });
    }
  }

  renderForm() {
    const { loading } = this.state;
    const { t, userType } = this.props;

    return (
      <>
        <h1 className={'weight-bold align-center'}>{t('page.email')}</h1>
        <p className={'align-center'}>{t('page.recoveryInstruction')}</p>
        <div className={'fields'}>
          <div className={'form'}>{InputsSet(this, ['email'])}</div>

          <Button
            type={'primary'}
            htmlType={'submit'}
            size={defaults.antd.button.size}
            loading={loading}
            block
          >
            {t('button.send')}
          </Button>
        </div>
        <LogInLink userType={userType} phrase={'backTo'} />
      </>
    );
  }

  renderSuccess() {
    const { userType, t } = this.props;

    return (
      <>
        <Clapping height={80} style={{ textAlign: 'center', marginBottom: 20 }} />

        <h1 className={'weight-bold align-center'}>{t('page.checkYourMail')}</h1>
        <p className={'align-center'}>{t('page.recoveryInstructionsSent')}</p>
        <p className={'align-center'}>{this.state.email.value}</p>

        <Link to={`/${userType}`}>
          <Button type={'primary'} htmlType={'submit'} size={defaults.antd.button.size} block>
            {t('button.backToLogin')}
          </Button>
        </Link>
      </>
    );
  }

  render() {
    const { form, formSubmited } = this.state;
    const { userType } = this.props;

    return (
      <Wrapper columns={defaults.variables.withTeidoWrapper}>
        <RegistrationBlockWrapper userType={userType}>
          <Form id={form} onSubmit={this.handleSubmit}>
            {formSubmited ? this.renderSuccess() : this.renderForm()}
          </Form>
        </RegistrationBlockWrapper>
      </Wrapper>
    );
  }
}

export default tConsume(PasswordRequest);

PasswordRequest.propTypes = {
  userType: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};
