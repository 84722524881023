import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'antd';
import axios from 'axios';
import getFormData from 'get-form-data';

import PhoneChange2step from './PhoneChange2step';

import api from 'API';
import { fieldValidation, fieldChange, responseError } from 'FIELDS';
import { InputsSet, PasswordSet } from 'INPUTS';
import { tConsume } from 'TRANSLATION';
import defaults from 'DEFAULTS';

class PhoneChange1step extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      form: 'phone_change',
      waitingCode: false,
      data: {},
      phone: {
        name: 'phone',
        visible: true,
        placeholder: true,
        required: true,
        validation: { isPhone: true },
      },
      password: {
        name: 'password',
        type: 'password',
        autoComplete: 'new-password',
        visible: true,
        required: true,
        validation: { isPassword: true },
        placeholder: true,
      },
      password_confirmation: {},
    };

    this.handleFieldChange = fieldChange.bind(this, this);
    this.fieldValidation = fieldValidation.bind(this, this);
    this.responseError = responseError.bind(this, this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  toggleModal(visible = !this.state.visible) {
    this.setState({ visible });
  }

  // also in use in PhoneChange2step for Resend confirmation code
  handleSubmit(event, data) {
    event.preventDefault();

    const { userType } = this.props;

    if (!data) {
      data = {
        ...getFormData(event.target, { trim: true }),
        password_confirmation: this.state.password.value,
      };
    }

    if (this.fieldValidation()) {
      this.setState({ loading: true });

      axios(api.users.profile.changePhoneRequest(userType).AXIOS('POST', data))
        .then(() => {
          if (this._mounted) {
            this.setState({
              waitingCode: true,
              data,
            });

            this.toggleModal(false);
          }
        })
        .catch((error) => {
          if (this._mounted) {
            this.responseError(error);
          }
        })
        .finally(() => {
          if (this._mounted) this.setState({ loading: false });
        });
    }
  }

  render() {
    const { form, loading, waitingCode, data } = this.state;
    const { t, userType, toggleModal } = this.props;

    if (waitingCode) {
      return (
        <PhoneChange2step
          userType={userType}
          data={data}
          toggleModal={toggleModal}
          confirmationCodeRequest={this.handleSubmit}
        />
      );
    }

    return (
      <Form onSubmit={this.handleSubmit} id={form}>
        <div className={'mb20 align-center'}>{t('component.phoneNumber.changeDescription')}</div>

        {InputsSet(this, ['phone'])}

        {PasswordSet(this, ['password'])}

        <Button
          type={'primary'}
          htmlType={'submit'}
          size={defaults.antd.button.size}
          loading={loading}
          block
        >
          {t('button.continue')}
        </Button>
      </Form>
    );
  }
}

export default tConsume(PhoneChange1step);

PhoneChange1step.propTypes = {
  userType: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
