import React, { Component } from 'react';
import { Spin, Icon } from 'antd';
import PropTypes from 'prop-types';

export default class Spinner extends Component {
  render() {
    const antIcon = <Icon type="loading" spin />;

    const { size } = this.props;
    return (
      <div className="spinner">
        <Spin indicator={antIcon} size={size} />
      </div>
    );
  }
}

Spinner.propTypes = {
  size: PropTypes.string,
};
