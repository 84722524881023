import merge from 'deepmerge';
import isObject from 'lodash.isobject';

/**
 *
 * Update value retated to input field
 * Required by child component
 * @param {*} self - context
 * @param {*} data - new inputs data
 *
 */
export default function (self, data) {
  const options = {
    customMerge: (key) => {
      if (key === 'value') {
        return (a, b) => b;
      }
    },

    arrayMerge: (a, b) => b,
  };

  if (!isObject(data)) {
    throw new Error('fieldChange error: recived "data" must be an object');
  }

  if (!data.name) {
    throw new Error('fieldChange error: prop "name" is missed in "data"');
  }

  if (!(data.name in self.state)) {
    throw new Error(`fieldChange error: prop "${data.name}" in missed in target state`);
  }

  self.setState((state) => {
    return {
      ...state,
      [data.name]: merge(state[data.name], data, options),
    };
  });
}
