import defaults from 'DEFAULTS';
const no_translation = defaults.translation.sub;
const translation_error = defaults.translation.error;

export default function (base, value, replace = true) {
  if (value) {
    if (typeof value !== 'string') {
      console.warn('Translation value must be a string');
      return translation_error;
    }

    return findTranslation(base, value);
  }

  return no_translation;

  function findTranslation(obj, value) {
    const props = value.split('.');
    let result = obj;
    for (let i = 0; i < props.length; i++) {
      let _prop = props[i].split('[');

      if (_prop[0] in result) {
        result = result[_prop[0]];

        if (_prop[1]) {
          if (result[parseInt(_prop[1])] !== undefined) {
            result = result[parseInt(_prop[1])];
          } else {
            result = replace ? no_translation : '';
            break;
          }
        }
      } else {
        if (replace) {
          result = _prop[0] === 'keys' ? [no_translation] : no_translation;
        } else {
          result = _prop[0] === 'keys' ? [] : '';
        }
        break;
      }
    }

    if (
      (defaults.dev || defaults.isLocalhost) &&
      replace &&
      result === no_translation &&
      !(value.split('.')[0] === 'page' && !(value.split('.')[0] in obj))
    ) {
      console.warn(`translation not found: ${value}`);
    }

    return result;
  }
}
