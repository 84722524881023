import msg from '../getErrorMsg';

export default (input, params, context) => {
  const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');

  if (!regex.test(input.value)) {
    return msg(context, 'passwordTooSimple');
  }

  return false;
};
