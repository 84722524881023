import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Item extends Component {
  render() {
    const { render, type, width } = this.props;

    if (!render) {
      return null;
    }

    const classes = ['item'].concat(Array.isArray(type) ? type : type.split(' '));

    const style = {};

    if (width) {
      style.style = { width };
    }

    return (
      <div className={classes.join(' ')} {...style}>
        {this.props.children}
      </div>
    );
  }
}

// type: {string, array of strings} - button, field, small-1...5

Item.propTypes = {
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  render: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Item.defaultProps = {
  type: '',
  render: true,
};
