import React from 'react';
import PropTypes from 'prop-types';

export default function InputDescription(props) {
  return (
    <div className="input-description">
      <p>{props.children}</p>
    </div>
  );
}

InputDescription.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]).isRequired,
};
