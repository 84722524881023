import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { tConsume } from 'TRANSLATION';
import Cyborg from '@atoms/cyborg';

import { PasswordError, PasswordSuccess } from 'IMAGE';

class PasswordCriteriaList extends Component {
  render() {
    const { t, validation, nameOfClass, nameOfInternalClass, cyborg } = this.props;

    return (
      <div className={nameOfClass}>
        <div className={`criteriaList ${nameOfInternalClass}`}>
          <ul>
            {Object.keys(t('component.confirmPassword')).map((key) => {
              const status = validation.indexOf(key) >= 0;

              return (
                <li key={key}>
                  {status ? (
                    <PasswordSuccess height={20} style={{ marginRight: 10 }} />
                  ) : (
                    <PasswordError height={20} className="color-red" style={{ marginRight: 10 }} />
                  )}
                  {t(`component.confirmPassword.${key}`)}
                </li>
              );
            })}
          </ul>
        </div>

        {cyborg && <Cyborg />}
      </div>
    );
  }
}

export default tConsume(PasswordCriteriaList);

PasswordCriteriaList.propTypes = {
  validation: PropTypes.array.isRequired,
  nameOfClass: PropTypes.string.isRequired,
  cyborg: PropTypes.bool.isRequired,
  nameOfInternalClass: PropTypes.string,
  t: PropTypes.func.isRequired,
};
