import React from 'react';

export default function Template() {
  return (
    <div>
      <h3>United States person:</h3>
      <p>Any person meeting at least one of the following criteria:</p>
      <ol>
        <li>
          The individual has citizenship or a valid residence permit (Green Card) in the U.S.;
        </li>
        <li>The individual’s tax residence country is the U.S.;</li>
        <li>The individual was born in the U.S.;</li>
        <li>The legal entity has a phone number with a U.S. country code;</li>
        <li>The legal entity is registered in the U.S.;</li>
        <li>
          The residential or correspondence address of the individual is in the U.S. (including a
          P.O. Box in the U.S.);
        </li>
        <li>
          A Power of Attorney has been issued to an individual/legal entity with an address in the
          U.S.
        </li>
      </ol>
    </div>
  );
}
