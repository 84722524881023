import isAlpha from 'validator/lib/isAlpha';
import msg from '../getErrorMsg';

export default (input, params, context) => {
  let regexPattern = new RegExp(
    '[' + params.ignore.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') + ']',
    'g'
  );
  let cleanedText = input.value.replace(regexPattern, '');
  if (cleanedText.length === 0) return false;

  if (!isAlpha(input.value, params.locale, { ignore: params.ignore })) {
    return msg(context, params.error ? params.error : 'notAlpha');
  }

  return false;
};
