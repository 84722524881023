import isObject from 'lodash.isobject';
//Rulesets
import Rules from './rules';

export default (data, context, closure, with_addon) => {
  if (!isObject(data)) {
    return false;
  }

  let errors = false;
  for (const key in data) {
    const item = data[key];
    if (isObject(item) && 'name' in item && !item.error) {
      if (!closure || (Array.isArray(closure) && closure.some((item2) => item2 === item.name))) {
        let checks = [];

        if (skipValidationIf(item, context)) {
          continue;
        }

        if (validateIf(item, context)) {
          const isRequired = 'required' in item && item.required;
          //Create an array of checks
          if (isRequired) {
            checks.push('isRequiredError');
          }

          if (isRequired || item.value) {
            if (item.type !== 'number' && typeof item.value === 'string' && !('keys' in item)) {
              checks.push('lengthError');
            }

            if (item.type === 'number') {
              checks.push('isNumericError');
            }

            if ('validation' in item && Object.keys(item.validation).length > 0) {
              checks = checks.concat(
                Object.keys(item.validation)
                  .map((key) => {
                    // if validation rule is boolean
                    if (typeof item.validation[key] === 'boolean') {
                      // validate if only 'true'
                      return item.validation[key] ? `${key}Error` : null;
                    }

                    // validation rule with param
                    return [`${key}Error`, item.validation[key]];
                  })
                  .filter((checkItem) => checkItem !== null)
              );
            }

            if ((with_addon && 'addonAfter' in item) || 'addonBefore' in item) {
              checks.push('addonError');
            }

            //Run checks
            if (checks.length > 0) {
              for (let i = 0; i < checks.length; i++) {
                let func,
                  params = {};

                if (typeof checks[i] === 'string') {
                  func = checks[i];
                }

                if (Array.isArray(checks[i])) {
                  func = checks[i][0];
                  params = checks[i][1];
                }

                let error = false;

                if (func in Rules) {
                  error = Rules[func](item, params, context);
                } else {
                  throw new Error(`Validation function '${func}' is not found`);
                }

                if (error) {
                  errors = !errors ? {} : errors;
                  errors[item.name] = {
                    status: 'error',
                    help: error,
                  };
                  break;
                }
              }
            }
          }
        }
      }
    }
  }

  return errors;
};

const skipValidationIf = (item, context) => {
  if (!isObject(item.skipValidationIf)) {
    return false;
  }

  let _key = Object.keys(item.skipValidationIf)[0];
  return context.state[_key].value == item.skipValidationIf[_key];
};

const validateIf = (item, context) => {
  if (!item.hasOwnProperty('validateIf')) {
    return true;
  }

  if (!isObject(item.validateIf)) {
    return item.validateIf;
  }

  let _key = Object.keys(item.validateIf)[0];
  return context.state[_key].value == item.validateIf[_key];
};
