import defaults from 'DEFAULTS';
import { Strings } from 'HELPERS';
import isObject from 'lodash.isobject';

export default (input, params, context) => {
  const error = checkAddon(input, 'addonAfter', context);

  if (error) {
    return error;
  }

  return checkAddon(input, 'addonBefore', context);
};

const checkAddon = (item, type, context) => {
  if (type in item && item[type].required && !item[type].value) {
    if (context && context.props && context.props.__tMounted) {
      const addonName = item[type].name;
      let addonLabel = item[type].label ? item[type].label : '';

      if (isObject(addonLabel)) {
        addonLabel = addonLabel.label || addonLabel.title || false;
      }

      if (!addonLabel) {
        addonLabel = addonName ? context.props.t(`field.${addonName}.label`) : false;
      }

      if (addonLabel) {
        return Strings.Compile(context.props.t('errors.addonSelectRequired'), [addonLabel]);
      }

      return context.props.t('errors.addonSelectRequiredDefault');
    }

    return defaults.validationError;
  }

  return false;
};
