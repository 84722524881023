import msg from '../getErrorMsg';
import defaults from 'DEFAULTS';

export default (input, params, context) => {
  if (!defaults.re.number.test(input.value)) {
    return msg(context, 'notPercentages');
  }

  if (parseFloat(input.value) < 0) {
    return msg(context, 'minOwnershipStake');
  }

  if (parseFloat(input.value) > 100) {
    return msg(context, 'maxOwnershipStake');
  }

  return false;
};
