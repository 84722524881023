/**
 *
 * @returns {string} device name
 *
 */
export default () => {
  const width = window.innerWidth > 0 ? window.innerWidth : screen.width;
  let result = 'phone';

  if (width >= 576) {
    result = 'phone-horizontal';
  }

  if (width >= 768) {
    result = 'tablet-vertical';
  }

  if (width >= 992) {
    result = 'tablet-horizontal';
  }

  if (width >= 1200) {
    result = 'desktop';
  }

  return result;
};
