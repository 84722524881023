import defaults from 'DEFAULTS';
import countryCodeEmoji from 'country-code-emoji';

const getCountryItem = ({ country_code, phone_code }) => {
  return {
    title: `${countryCodeEmoji(country_code)} +${phone_code}`,
    value: `${country_code}#${phone_code}`,
  };
};

export default () => {
  return {
    keys() {
      const data = [];

      for (const index in defaults.location.country) {
        const country = defaults.location.country[index];

        data.push(getCountryItem(country));
      }

      return data;
    },
    formater(value) {
      return value.split('#')[1];
    },
  };
};
