import React from 'react';
import FormInput from '@atoms/form/form-item/FormInput';
import setDisabled from '@atoms/form/funcs/setDisabled';

export default (self = {}, array = []) => {
  const state = self.state;

  if (!state) {
    return null;
  }

  return (
    <>
      {array.map((element) => {
        const object = self.state[element];
        const { disabled, _static } = setDisabled(object, self);

        return (
          <FormInput
            textarea={true}
            key={element}
            onChange={self.handleFieldChange}
            onBlur={self.handleFieldBlur ? self.handleFieldBlur : false}
            item={object}
            disabled={disabled}
            loading={self.state.loading}
            validation={object.validation}
            _static={_static}
          />
        );
      })}
    </>
  );
};
