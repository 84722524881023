import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { fetchAllPersonalData } from '@features/personalSlice';
import SectionHeader from '@molecules/section-header';
import Spinner from '@atoms/spinner';
import { Fade, FadeDown, animOffsets } from '@atoms/transitions';
import PersonalInformation from '@organisms/personal-profile/PersonalInformation';
import CompanyInformation from '@organisms/personal-profile/CompanyInformation';
import PasswordChange from '@organisms/personal-profile/PasswordChange';
import Passphrase from '@organisms/personal-profile/Passphrase';
import { ProfileTfa } from '@organisms/personal-profile/ProfileTfa';
import Notifications from '@organisms/personal-profile/Notifications';

import { tConsume } from 'TRANSLATION';

const PersonalProfile = ({ userType, t }) => {
  const personalData = useSelector((state) => state.personal.data);
  const dispatch = useDispatch();

  const animConfig = {
    i: 6,
    options: {
      1: {
        condition:
          personalData.profile_type === 'DIRECTOR' &&
          personalData.company &&
          personalData.company.name,
      },
      2: {
        condition: 1,
      },
      5: {
        condition: userType === 'aemi',
      },
    },
  };
  const offsets = animOffsets(animConfig);

  if (!personalData || !personalData.profile_type) {
    return <Spinner />;
  }

  const updateData = () => {
    dispatch(fetchAllPersonalData);
  };

  return (
    <>
      <Fade delay={offsets[0]}>
        <SectionHeader title={<h2>{t('page.headers.profile')}</h2>} />
      </Fade>

      <Row gutter={40}>
        <Col lg={12}>
          <FadeDown delay={offsets[0]}>
            <PersonalInformation
              userType={userType}
              personalData={personalData}
              fetchAllPersonalData={updateData}
            />
          </FadeDown>

          <FadeDown delay={offsets[1]}>
            <CompanyInformation userType={userType} />
          </FadeDown>

          <FadeDown delay={offsets[2]}>
            <Passphrase userType={userType} />
          </FadeDown>

          <FadeDown delay={offsets[5]}>
            <Notifications userType={userType} />
          </FadeDown>
        </Col>

        <Col lg={12}>
          <FadeDown delay={offsets[3]}>
            <PasswordChange userType={userType} personalData={personalData} />
          </FadeDown>
          <FadeDown delay={offsets[4]}>
            <ProfileTfa userType={userType} personalData={personalData} />
          </FadeDown>
        </Col>
      </Row>
    </>
  );
};

export default tConsume(PersonalProfile);

PersonalProfile.propTypes = {
  userType: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};
