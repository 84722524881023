import isObject from 'lodash.isobject';
import defaults from 'DEFAULTS';
import { notHTML } from 'HELPERS';

export function prepareDataForPreview({ value, label }) {
  let _label = label,
    _value = value;
  const false_positive = [0, false];

  if (
    (!_value && false_positive.indexOf(_value) < 0) ||
    (Array.isArray(_value) && !_value.length)
  ) {
    return { isValueValid: false };
  }

  if (Array.isArray(_value)) {
    _value = _value.join(', ');
  }

  if (isObject(_label)) {
    if (_label.label || _label.title) {
      _label = _label.label || _label.title;
    }
  }

  if (typeof _label !== 'string' && notHTML(_label)) {
    if (defaults.dev || defaults.isLocalhost) {
      throw 'label must be a string or HTML element, got: ' + typeof _label;
    }

    _label = defaults.translation.defaultLabel;
  }

  return { isValueValid: true, _value, _label };
}
