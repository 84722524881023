import isObject from 'lodash.isobject';

export default function (object, self) {
  const _static = ('_static' in self.state && self.state._static) || object._static;
  let disabled = _static;

  if (!disabled && object.disabled !== undefined) {
    if (typeof object.disabled === 'boolean') {
      disabled = object.disabled;
    }

    if (isObject(object.disabled)) {
      disabled = Object.keys(object.disabled).some(
        (key) => self.state[key].value == object.disabled[key]
      );
    }
  }

  return { disabled, _static };
}
