import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ render: Component, authorized, userType, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!authorized) {
        return <Redirect to={{ pathname: `/${userType}` }} />;
      }

      return <Component {...props} />;
    }}
  />
);

export default PrivateRoute;
