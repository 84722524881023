import React, { Component } from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import parseBool from '@common/helps/parseBool';

export default class InputRow extends Component {
  constructor(props) {
    super(props);

    this.labelAction = this.labelAction.bind(this);
  }

  labelAction(event) {
    const { disabled, item, labelAction } = this.props;

    if (disabled || !item || !labelAction || event.target.tagName !== 'DIV') {
      return false;
    }

    labelAction({
      name: item.name,
      value: parseBool(!item.value),
      help: '',
      status: '',
    });
  }

  render() {
    const { flex, style, label, disabled, children, labelAction, item } = this.props;
    const _flex = {
      type: 'flex',
      justify: 'space-between',
      className: 'inputRow',
      ...flex,
    };

    const labelClasses = [];

    if (disabled) {
      labelClasses.push('disabled');
    }

    if (item && item.readOnly) {
      labelClasses.push('read-only-label');
    }

    if (!disabled && labelAction && item) {
      labelClasses.push('active-label');
    }

    return (
      <Row {..._flex} style={style}>
        <Col
          className={labelClasses.join(' ')}
          style={{ paddingRight: '10px' }}
          onClick={this.labelAction}
        >
          {label}
        </Col>
        <Col>{children}</Col>
      </Row>
    );
  }
}

InputRow.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  flex: PropTypes.object,
  style: PropTypes.object,
  labelAction: PropTypes.func,
  item: PropTypes.object,
};

InputRow.defaultProps = {
  label: '',
  flex: {},
  style: {},
};
