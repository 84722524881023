import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class SectionHeader extends Component {
  render() {
    const { title } = this.props;

    return (
      <div className="section-header">
        <div className="section-header-wrapper">
          <div className="section-header-title">{title}</div>

          <div className="section-header-container">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

SectionHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
