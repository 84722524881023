const default_lang = 'en-GB';
const allowed_lang = [
  {
    title: 'English (United Kingdom)',
    value: 'en-GB',
    parent: 'en',
  },
  // {
  //   title: '中文',
  //   value: 'zh-HK',
  //   parent: 'zh',
  // },
];

const allowedLang = allowed_lang.map(({ value }) => value);
const parentLang = allowed_lang.map(({ parent }) => parent);

const setLanguage = (lang = default_lang) => {
  localStorage.setItem('language', lang);
  location.reload();
};

const getBrowserLanguage = () => {
  const navigatorLang = navigator.languages && navigator.languages[0];
  const browserLang = navigatorLang || navigator.language || navigator.userLanguage;
  const selectedLang = localStorage.getItem('language');

  if (selectedLang && allowedLang.indexOf(selectedLang) >= 0) {
    return selectedLang;
  }

  if (browserLang) {
    const browserParentLang = browserLang.split('-')[0];

    if (allowed_lang.indexOf(browserLang) >= 0) {
      return browserLang;
    }

    if (browserParentLang && parentLang.indexOf(browserParentLang) >= 0) {
      for (let counter = 0; counter < allowed_lang.length; counter++) {
        const { parent, value } = allowed_lang[counter];

        if (parent === browserParentLang) {
          return value;
        }
      }
    }
  }

  return default_lang;
};

export default {
  lang: getBrowserLanguage(),
  default_lang,
  allowed_lang: allowed_lang,
  setLang: setLanguage,
  sub: '-NO-TRANSLATION-',
  error: '-TRANSLATION-ERROR-',
  defaultLabel: 'LABEL',
};
