import React from 'react';

export default function Template() {
  return (
    <div>
      <h3>Proof of Residence: </h3>
      <ul>
        <li>Utility bill;</li>
        <li>Account statement or reference letter issued by licensed financial institution;</li>
        <li>ID document (different from the document that is used to confirm identity);</li>
        <li>Other documents that contain the address and are issued by government authority.</li>
      </ul>
      <hr />
      <p>
        Note, that document shall include the full name, full address, issue date and name of
        issuing institution/authority. Document shall not be older than 3 months (except ID
        document).
      </p>
    </div>
  );
}
