import React from 'react';

export default function Template() {
  return (
    <div>
      <h3>High risk industries mean the following types of business activities:</h3>
      <ul>
        <li>
          Financial institutions, virtual currencies, money remittance, investment, currency
          exchange and insurance companies;
        </li>
        <li>State-owned companies and sovereign wealth funds;</li>
        <li>Funds, Trusts, Foundations;</li>
        <li>Non-profit and religious organizations;</li>
        <li>Jewelry or precious metal manufacture/trading;</li>
        <li>Pharmaceutical products manufacture/trading;</li>
        <li>Construction services;</li>
        <li>Mining equipment manufacture/trading;</li>
        <li>Real estate brokers;</li>
        <li>Chemical or Petroleum products manufacture/wholesale.</li>
      </ul>
      <br />
      <h3>Prohibited industries mean the following types of business activities:</h3>
      <ul>
        <li>Shell Financial Institutions;</li>
        <li>Unlicensed financial institutions;</li>
        <li>Gambling activities;</li>
        <li>
          Activities which promote goods or services related to racism, religious persecution, hate,
          violence, sexism, etc;
        </li>
        <li>Adult services;</li>
        <li>Auction Sites;</li>
        <li>Dangerous & Hazardous Goods (combustibles, corrosives, etc);</li>
        <li>Drugs & illicit substances and related paraphernalia;</li>
        <li>Goods and services infringing intellectual property rights of a third party;</li>
        <li>Lotteries;</li>
        <li>Political Organisations;</li>
        <li>Pyramid /Ponzi Schemes;</li>
        <li>Tobacco online trading;</li>
        <li>Weapon trading.</li>
      </ul>
      <br />
      <p>
        We can refuse to let you open or hold an Account if you are related to any other industry or
        activity that we are not comfortable with.
      </p>
    </div>
  );
}
