export function downloadFile({ data, headers, callback }) {
  if (!data || !headers) return;

  const contentDisposition = headers['content-disposition'];
  const contentType = headers['content-type'];
  let name;

  const isCyrillic = contentDisposition.match(/filename\*=utf-8''/);

  if (isCyrillic) {
    name = contentDisposition.split(/filename\*=utf-8''/)[1].split(';')[0];
  } else {
    name = contentDisposition.split('filename=')[1].split(';')[0];
  }

  name = name.replace(/(^"|"$)/g, '');
  const decodedName = decodeURI(name);

  const url = window.URL.createObjectURL(new Blob([data], { type: contentType }));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', decodedName);

  document.body.appendChild(link);

  link.click();

  if (!!callback && typeof callback === 'function') {
    callback();
  }
}
