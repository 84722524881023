import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import api from 'API';
import { clearUserToken } from './userTokenSlice';
import { withNotificationForError } from './helper';

export const fetchCustomerData = createAsyncThunk(
  'customer/fetchCustomerData',
  withNotificationForError(async (id) => {
    const response = await axios(api.customers.customerInfo(id).AXIOS('get'));

    return response.data.data;
  }),
  {
    condition: (payload) => {
      if (!payload) return false;
    },
  }
);

const initialState = {
  loading: false,
  data: {},
  error: null,
};

const customerSlice = createSlice({
  name: 'customer',
  initialState: initialState,
  reducers: {
    clearCustomer() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCustomerData.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(fetchCustomerData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(clearUserToken, () => {
        return initialState;
      });
  },
});

export const { clearCustomer } = customerSlice.actions;
export default customerSlice.reducer;
