/**
 *
 * @param {string} link
 * @param {*} replacing key and string
 * @returns {string} link
 *
 */
export default (link, params) => {
  if (params) {
    Object.keys(params).map((key) => {
      link = link.replace(':' + key, params[key]);
    });
  }

  return link;
};
