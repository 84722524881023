import msg from '../getErrorMsg';

export default (input, params, context) => {
  const val = typeof input.value === 'string' ? input.value.trim() : input.value;

  if (
    val === undefined ||
    ((typeof val === 'string' || Array.isArray(val)) && val.length === 0) ||
    val === null ||
    (typeof val === 'object' && Object.keys(val).length === 0) ||
    // for range input
    (input.range && Array.isArray(val) && val.some((item) => item === null))
  ) {
    return msg(context, 'required');
  }

  return false;
};
