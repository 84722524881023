import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Logo as DefaultLogo, Favicon } from 'IMAGE';

const Logo = ({ small, height, route, adaptive }) => {
  const [logoHeight, setLogoHeight] = useState(height);
  const LogoImage = small ? Favicon : DefaultLogo;

  useEffect(() => {
    if (adaptive) {
      getLogoHeight();
      window.addEventListener('resize', getLogoHeight);
    }

    return () => {
      window.removeEventListener('resize', getLogoHeight);
    };
  }, []);

  useEffect(() => {
    if (!adaptive) setLogoHeight(height);
  }, [height]);

  const getLogoHeight = () => setLogoHeight(window.innerWidth >= 768 ? 120 : 60);

  const LogoComponent = (
    <LogoImage
      height={logoHeight}
      width={small ? logoHeight : '100%'}
      style={{ borderRadius: 4 }}
    />
  );

  if (route) {
    return (
      <Link to={route} className={'align-center logo'}>
        {LogoComponent}
      </Link>
    );
  }

  return LogoComponent;
};

export default Logo;

Logo.propTypes = {
  height: PropTypes.number,
  route: PropTypes.string,
  small: PropTypes.bool,
  adaptive: PropTypes.bool,
};

Logo.defaultProps = {
  height: 66,
  small: false,
  adaptive: false,
};
