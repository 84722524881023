import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { tConsume } from 'TRANSLATION';

function LogInLink({ userType, t, phrase = 'alreadyHave' }) {
  return (
    <div className={'account-link'}>
      {`${t(`component.login.${phrase}`)} `}
      <Link to={`/${userType}`}>{t('component.login.logIn')}</Link>
    </div>
  );
}

export default tConsume(LogInLink);

LogInLink.propTypes = {
  userType: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  phrase: PropTypes.string,
};
