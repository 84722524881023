import React from 'react';
import isObject from 'lodash.isobject';

export default function (object, t) {
  let label = '';
  const _label = object.label;
  const name = object.name;

  if (_label) {
    if (isObject(_label) && _label.label) {
      label = _label.label;
    }

    if ((isObject(_label) && React.isValidElement(_label)) || typeof _label === 'string') {
      label = _label;
    }
  }

  if (!label && _label !== false && name && t && typeof t === 'function') {
    label = t(`field.${name}.label`);
  }

  return label;
}
