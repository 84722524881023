import React from 'react';
import compare from './compare';
import processor from './processor';

const T = React.createContext();

function tProvide(ComponentToWrap) {
  return class SET_TRANSLATION extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        translation: processor.bind(null, compare()),
        page: null,
        setPage: (page) => {
          if (page) {
            this.setState({ translation: processor.bind(null, compare(page)), page: page });
          }
        },
      };
    }

    render() {
      return (
        <T.Provider value={this.state}>
          <ComponentToWrap {...this.props} />
        </T.Provider>
      );
    }
  };
}

function tConsume(ComponentToWrap) {
  class TranslationWrapper extends React.Component {
    render() {
      const { forwardedRef, ...rest } = this.props;
      return (
        <T.Consumer>
          {(t) => (
            <ComponentToWrap ref={forwardedRef} t={t.translation} __tMounted={true} {...rest} />
          )}
        </T.Consumer>
      );
    }
  }

  return React.forwardRef((props, ref) => {
    return <TranslationWrapper {...props} forwardedRef={ref} />;
  });
}

export { T, tProvide, tConsume };
