import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';

import PagePasswordRequest from '@templates/authorization/PasswordRequest';
import PagePasswordReset from '@templates/authorization/PasswordReset';
import { PageRoute } from 'TRANSLATION';

export default class PasswordResetPage extends Component {
  render() {
    const { userType, route, redirectRoute } = this.props;

    const routeReset = `${route}/:token`;

    return (
      <Switch>
        <PageRoute
          page="authorization"
          exact
          path={route}
          render={(t) => <PagePasswordRequest userType={userType} route={route} {...t} />}
        />

        <PageRoute
          page="authorization"
          path={routeReset}
          render={(data, t) => (
            <PagePasswordReset
              userType={userType}
              token={data.match.params.token}
              redirectRoute={redirectRoute}
              {...t}
            />
          )}
        />
      </Switch>
    );
  }
}

PasswordResetPage.propTypes = {
  userType: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  redirectRoute: PropTypes.string.isRequired,
};
