import React from 'react';
import FormTreeSelect from '@atoms/form/form-item/FormTreeSelect';
import setDisabled from '@atoms/form/funcs/setDisabled';

export default (self = {}, array = []) => {
  const state = self.state;

  if (!state) {
    return null;
  }

  return (
    <>
      {array.map((element) => {
        const object = state[element];

        const { disabled, _static } = setDisabled(object, self);

        let _onSearch = object.onSearch;

        if (typeof _onSearch === 'function') {
          _onSearch = _onSearch.bind(null, self);
        }

        return (
          <FormTreeSelect
            key={element}
            onChange={self.handleFieldChange}
            onSelect={object.onSelect}
            onSearch={_onSearch}
            item={object}
            loading={self.state.loading}
            disabled={disabled}
            _static={_static}
          />
        );
      })}
    </>
  );
};
