import React from 'react';

export default function Cyborg() {
  return (
    <>
      <img src="/img/teido.svg" className="cyborg" />
      <div className="shadow" />
    </>
  );
}
