import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { tConsume } from 'TRANSLATION';

import { NavLink } from 'react-router-dom';
import { Icon } from 'antd';

class RenderLink extends Component {
  /**
   *
   * Getting full redirect route
   * @param {string} route
   * @returns {string} route
   *
   */
  getRoute(route = '') {
    route = route.trim() ? `/${route.trim()}` : '';

    return `${this.props.route}${route}`;
  }

  render() {
    const { userType, item, t } = this.props;
    const { path, exit, icon, name } = item;

    const _name = t(`menu.${name}`);
    let href = this.getRoute(path);

    if (exit) {
      href = `/${userType}/logout`;
    }

    return (
      <NavLink to={href} onClick={exit && this.logOutUser}>
        {icon && <Icon type={icon || 'border'} />}
        <span>{_name}</span>
      </NavLink>
    );
  }
}

export default tConsume(RenderLink);

RenderLink.propTypes = {
  userType: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  route: PropTypes.string.isRequired,
};
