import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import Spinner from '@atoms/spinner';
import { changePersonalDataField } from '@features/personalSlice';
import notification from '@helps/notification';
import { TfaQrForm } from '@organisms/personal-profile/ProfileTfa';

import api from 'API';
import { responseError } from 'FIELDS';
import { tConsume } from 'TRANSLATION';
import { Strings } from 'HELPERS';
import defaults from 'DEFAULTS';

const TfaQr = ({
  auth = false,
  type,
  userType,
  email,
  password,
  successCallback,
  errorCallback,
  t,
}) => {
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [qrSrc, setQrSrc] = useState(null);
  const { localStorageKey } = defaults;

  const dispatch = useDispatch();

  useEffect(async () => {
    if (type === 'bind') {
      await getQr();
    } else {
      setLoading(false);
    }
  }, []);

  const getQr = async () => {
    const checkData = { email, password };
    let authData = { id: sessionStorage.getItem('registration-id') };

    const CONFIG = auth
      ? api.users.registration.google_auth_step_1(userType).AXIOS('POST', authData)
      : type === 'check'
      ? api.tfa.googleAuthQrCode(userType).AXIOS('GET', checkData)
      : api.tfa.googleQrCode().AXIOS('GET');
    try {
      setLoading(true);
      const result = await axios(CONFIG);

      setQrSrc(result.data.QR);
    } catch (error) {
      responseError(error);
    } finally {
      setLoading(false);
    }
  };

  const checkCode = async ({ tfa_code }) => {
    const data = {
      tfa_code: tfa_code,
      email,
      password,
    };

    return await axios(api.tfa.checkGoogleAuthTfa(userType).AXIOS('POST', data));
  };

  const bindMethod = async ({ tfa_code }) => {
    const data = { tfa_code };
    let authData = { id: sessionStorage.getItem('registration-id'), tfa_code };

    const CONFIG = auth
      ? api.users.registration.google_auth_step_2(userType).AXIOS('POST', authData)
      : api.tfa.bindGoogleTfa().AXIOS('POST', data);

    const title = t('component.tfa.tfa_google_title');

    const result = await axios(CONFIG);

    dispatch(changePersonalDataField(result.data));
    notification(Strings.Compile(t('component.tfa.method_added'), [title]), 'success');

    return result;
  };

  const handleConfirm = async ({ tfa_code }) => {
    if (type === 'get') {
      return successCallback({
        tfa_code: tfa_code,
        tfa_method: 'google',
      });
    }

    try {
      setActionLoading(true);
      const result =
        type === 'check' ? await checkCode({ tfa_code }) : await bindMethod({ tfa_code });
      localStorage.removeItem(localStorageKey.tfaTimerEnd);
      await successCallback?.(result);
    } catch (error) {
      responseError(null, error);
      await errorCallback?.(error);
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <div className={'qr_container'}>
      {loading ? (
        <Spinner />
      ) : (
        <TfaQrForm type={type} loading={actionLoading} qrSrc={qrSrc} onConfirm={handleConfirm} />
      )}
    </div>
  );
};

export default tConsume(TfaQr);

TfaQr.propTypes = {
  auth: PropTypes.bool,
  type: PropTypes.oneOf(['bind', 'get', 'check']),
  userType: function (props, propName, componentName) {
    if (
      props['type'] === 'check' &&
      (props[propName] === undefined || typeof props[propName] !== 'string')
    ) {
      return new Error(`Invalid prop ${propName} supplied to ${componentName}. Validation failed.`);
    }
  },
  email: function (props, propName, componentName) {
    if (
      props['type'] === 'check' &&
      (props[propName] === undefined || typeof props[propName] !== 'string')
    ) {
      return new Error(`Invalid prop ${propName} supplied to ${componentName}. Validation failed.`);
    }
  },
  password: function (props, propName, componentName) {
    if (
      props['type'] === 'check' &&
      (props[propName] === undefined || typeof props[propName] !== 'string')
    ) {
      return new Error(`Invalid prop ${propName} supplied to ${componentName}. Validation failed.`);
    }
  },
  successCallback: PropTypes.func,
  errorCallback: PropTypes.func,
  t: PropTypes.func.isRequired,
};
