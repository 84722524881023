import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

export default class Transitions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startAnimation: false,
      counterStep: 100,
      timeout: 0,
      animState: null,
    };

    this.onEnter = this.onEnter.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    this.timeoutCounter = null;

    this.initCounter();
  }

  componentWillUnmount() {
    this._mounted = false;

    if (this.timeoutCounter) {
      clearInterval(this.timeoutCounter);
    }
  }

  componentDidUpdate(prevProps) {
    const { delay } = this.props;

    if (delay !== prevProps.delay && !isNaN(+delay)) {
      this.initCounter();
    }
  }

  initCounter() {
    const { counterStep } = this.state;
    const { delay } = this.props;

    if (+delay) {
      this.timeoutCounter = setInterval(() => {
        this.runCounter(delay);
      }, counterStep);

      return;
    }

    this.setState({ startAnimation: true });
  }

  runCounter(delay = this.props.delay) {
    const { counterStep, timeout } = this.state;

    if (this._mounted) {
      if (timeout >= +delay) {
        clearInterval(this.timeoutCounter);

        this.setState({
          timeout: 0,
          startAnimation: true,
        });

        return;
      }

      this.setState({ timeout: timeout + counterStep });
    }
  }

  onEnter() {
    this.setState({ animState: 'enter' });
  }

  render() {
    const { startAnimation } = this.state;
    const { delay, duration, type, children, enter, exit, onExited } = this.props;

    if (!delay && new String(delay) !== '0') {
      return null;
    }

    const wrappedClasses = [];

    if (!startAnimation) {
      wrappedClasses.push('hide-before-enter');
    }

    return (
      <CSSTransition
        enter={enter}
        exit={exit}
        onExited={onExited}
        classNames={`transition-${type}`}
        timeout={duration}
        in={startAnimation}
        onEnter={this.onEnter}
      >
        <div className={wrappedClasses.join(' ')}>{children}</div>
      </CSSTransition>
    );
  }
}

Transitions.propTypes = {
  //Component wrapped props
  enter: PropTypes.any,
  exit: PropTypes.any,
  onExited: PropTypes.any,
  /////////////////////////

  type: PropTypes.string.isRequired,
  start: PropTypes.bool,
  delay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  duration: PropTypes.number,
};

Transitions.defaultProps = {
  start: true,
  duration: 500,
};
