import isObject from 'lodash.isobject';
import defaults from 'DEFAULTS';
import merge from 'deepmerge';

export default function (self, data, loading, _static, callback) {
  const response_values = {};
  const fields_list = [];
  const _loading = loading ? { [loading]: false } : {};
  const __static = _static && typeof _static !== 'function' ? { _static: true } : {};

  Object.keys(self.state).forEach((key) => {
    let item = self.state[key];

    if (isObject(item) && !item.notField && item.name) {
      fields_list.push({ name: item.name });

      if ('validation' in item && item.validation.isPhone) {
        fields_list.push({
          name: `country_${item.name}_code`,
          parent: item.name,
          type: 'addonBefore',
          value: (value) => defaults.location.phoneCode(value),
        });
      }

      if ('addonBefore' in item && item.addonBefore.name) {
        fields_list.push({
          name: item.addonBefore.name,
          parent: item.name,
          type: 'addonBefore',
        });
      }

      if ('addonAfter' in item && item.addonAfter.name) {
        fields_list.push({
          name: item.addonAfter.name,
          parent: item.name,
          type: 'addonAfter',
        });
      }
    }
  });

  fields_list.forEach((item) => {
    if (item.name in data) {
      let value = data[item.name];
      let keys = {};

      if (isObject(value)) {
        if (data[item.name].title) {
          keys = {
            keys: [{ ...value }],
            temporaryKeys: true,
          };
          value = value.value;
        }
      }

      if (!('parent' in item)) {
        response_values[item.name] = {
          ...(item.name in response_values ? response_values[item.name] : {}),
          value: item.value ? item.value(value) : value,
          ...keys,
        };

        if (_static && typeof _static === 'function') {
          response_values[item.name]._static = _static(item, response_values[item.name]);
        }
      }

      if ('parent' in item) {
        response_values[item.parent] = {
          ...(item.parent in response_values ? response_values[item.parent] : {}),
          [item.type]: {
            value: item.value ? item.value(value) : value,
            ...keys,
          },
        };
      }
    }
  });

  self.setState(
    {
      ...merge(self.state, response_values),
      ..._loading,
      ...__static,
    },
    () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    }
  );
}
