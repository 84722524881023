import defaults from 'DEFAULTS';

import GoogleTag from './google-tag';

/**
 *
 * Load production scripts
 *
 */
if (!defaults.dev) {
  const scripts = [GoogleTag.bind(null, defaults.gtm)];

  scripts.map((code) => {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.text = typeof code === 'function' ? code() : code;

    document.head.appendChild(script);
  });
}
