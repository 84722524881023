import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import defaults from 'DEFAULTS';
import { tConsume } from 'TRANSLATION';
import { downloadFile } from '@helps/downloadFile';
import axios from 'axios';

class ExportTable extends Component {
  constructor(props) {
    super(props);
  }

  saveExport() {
    const { exportConfig } = this.props;
    axios(exportConfig).then((response) => {
      downloadFile({
        data: response.data,
        headers: response.headers,
      });
    });
  }

  render() {
    const { t } = this.props;

    return (
      <Button
        type="primary"
        htmlType="submit"
        size={defaults.antd.button.size}
        icon="save"
        block
        onClick={() => {
          this.saveExport();
        }}
      >
        {t('button.export')}
      </Button>
    );
  }
}

export default tConsume(ExportTable);

ExportTable.propTypes = {
  exportConfig: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  t: PropTypes.func.isRequired,
};
