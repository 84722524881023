import React from 'react';
import PropTypes from 'prop-types';
import Transitions from '../core';

export default function FadeDown(props) {
  return <Transitions type="fade-down" {...props} />;
}

FadeDown.propTypes = {
  start: PropTypes.bool,
  delay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  duration: PropTypes.number,
};
